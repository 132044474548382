import React from 'react';
import classNames from 'classnames';

const TOTAL_COLUMNS = 12;


interface IProps {
    id: string;
    width?: number;
    center?: boolean;
    autoHeight?: boolean;
}


interface IState {
}


export class View extends React.PureComponent<IProps, IState> {

    static defaultProps = {
        center: false,
        autoHeight: false,
    };


    render () {
        const classes = {
            'view': true,
            'view--auto-height': this.props.autoHeight,
            [`${this.props.id}-view`]: true,
            [`col-${this.props.width}`]: this.props.width !== undefined,
        };

        if (this.props.center && this.props.width) {
            const pushCols = Math.floor((TOTAL_COLUMNS - this.props.width) / 2);
            classes[`push-${pushCols}`] = true;
        }

        return (
            <div className={classNames(classes)}>
                {this.props.children}
            </div>
        );
    }
}
