import React from "react";
import {connect} from 'react-redux';
import {IReduxState} from '../reducers.interfaces';
import {setNavDrawerOpen} from '../dispatchers';
import {navigateTo} from '../actions';
import {GlobalNavBar as Component} from "./GlobalNavBar.component";


interface IOwnProps {
}


interface IReduxProps {
    navDrawerOpen: boolean;
    isOnline: boolean;
    viewStack: () => string[];
    getNavBarTitle: () => string | JSX.Element | null;
    getNavBarContentLeft: () => (JSX.Element | JSX.Element[] | null);
    getNavBarContentRight: () => (JSX.Element | JSX.Element[] | null);
}


interface IProps extends IOwnProps, IReduxProps {
}


interface IState {
    groupSelectorOpen: boolean;
}


class GlobalNavBarContainer extends React.PureComponent<IProps, IState> {
    public state: IState = {
        groupSelectorOpen: false,
    };


    private readonly onToggleNavDrawer = () => {
        window._paq.push(['trackEvent', 'GlobalNavBar', 'onToggleNavDrawer']);
        setNavDrawerOpen(!this.props.navDrawerOpen);
    }


    private readonly onBack = () => {
        window._paq.push(['trackEvent', 'GlobalNavBar', 'onBack']);
        const viewStack = this.props.viewStack();
        viewStack.pop();
        const url = viewStack.pop();
        if (!url) {
            return;
        }
        navigateTo(url);
    }


    render () {
        const viewStack = this.props.viewStack();
        const showBackButton = (viewStack.length > 1);
        return (
            <Component
                navDrawerOpen={this.props.navDrawerOpen}
                isOnline={this.props.isOnline}
                showBackButton={showBackButton}
                getNavBarTitle={this.props.getNavBarTitle}
                getNavBarContentLeft={this.props.getNavBarContentLeft}
                getNavBarContentRight={this.props.getNavBarContentRight}
                onBack={this.onBack}
                onToggleNavDrawer={this.onToggleNavDrawer}
            />
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IProps => {
    return {
        navDrawerOpen: state.ui.navDrawerOpen,
        isOnline: state.ui.isOnline,
        viewStack: state.ui.viewStack,
        getNavBarTitle: state.ui.getNavBarTitle,
        getNavBarContentLeft: state.ui.getNavBarContentLeft,
        getNavBarContentRight: state.ui.getNavBarContentRight,
        ...ownProps,
    };
};

export const GlobalNavBar = connect(mapStateToProps)(GlobalNavBarContainer);
