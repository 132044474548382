import {Intent, Spinner, Classes, H4} from "@blueprintjs/core";
import React from "react";
import {View} from "./wrappers/View";


interface IProps {
    message?: string;
}


interface IState {}


export class Loading extends React.PureComponent<IProps, IState> {

    static defaultProps: Partial<IProps> = {
        message: "Loading an absurd amount of Javascript…",
    };


    render() {
        return (
            <View id="loading">
                <div className={`${Classes.NON_IDEAL_STATE}`}>
                    <div className={`${Classes.NON_IDEAL_STATE_VISUAL}`}>
                        <Spinner className={`${Classes.LARGE}`} intent={Intent.PRIMARY} />
                    </div>
                    <H4>{this.props.message}</H4>
                </div>
            </View>
        );
    }
}
