import React from 'react';
import classNames from 'classnames';
import {Classes, IconName} from "@blueprintjs/core";


export const enum InputGroupType {
    INPUT,
    SELECT,
}


interface IProps {
    type?: InputGroupType;
    icon?: IconName;
    large?: boolean;
    invalid?: boolean;
}


interface IState {
}


export class InputGroup extends React.PureComponent<IProps, IState> {
    private buildIcon () {
        if (!this.props.icon) {
            return null;
        }

        const iconClassName = Classes.iconClass(this.props.icon);
        const iconClasses = classNames({
            [Classes.ICON]: true,
            [iconClassName]: true,
        });

        return (
            <span className={iconClasses}></span>
        );
    }


    render () {
        const type = this.props.type || InputGroupType.INPUT;
        const inputGroupClassNames = classNames({
            [Classes.INPUT_GROUP]: true,
            [Classes.SELECT]: (type === InputGroupType.SELECT),
            [`${Classes.SELECT}-with-icon`]: (type === InputGroupType.SELECT) && !!this.props.icon,
            [Classes.LARGE]: this.props.large,
            [Classes.INTENT_DANGER]: this.props.invalid,

        });

        return (
            <div className={inputGroupClassNames}>
                {this.buildIcon()}
                {this.props.children}
            </div>
        );
    }
}
