import {default as loadable, LoadableComponent} from '@loadable/component';
import {getPattern} from './urls';
import {IBaseViewContainerOwnProps} from './views/_base';


interface IView {
    pattern: string;
    Component: LoadableComponent<IBaseViewContainerOwnProps>;
}


export const VIEWS: IView[] = [
    // Territories
    {
        pattern: getPattern('territory-address-list'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryAddressTable" */
            './views/TerritoryAddressTable.container')).TerritoryAddressTable),
    },
    {
        pattern: getPattern('territory-address-print'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryAddressPrintTable" */
            './views/TerritoryAddressPrintTable.container')).TerritoryAddressPrintTable),
    },

    {
        pattern: getPattern('territory-history-list'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryHistoryTable" */
            './views/TerritoryHistoryTable.container')).TerritoryHistoryTable),
    },
    {
        pattern: getPattern('territory-checkout'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryCheckoutForm" */
            './views/TerritoryCheckoutForm.container')).TerritoryCheckoutForm),
    },
    {
        pattern: getPattern('territory-map'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryMap" */
            './views/TerritoryMap.container')).TerritoryMap),
    },
    {
        pattern: getPattern('territory-list'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-Territories" */
            './views/Territories.container')).Territories),
    },


    // Territory Groups
    {
        pattern: getPattern('territory-group-create'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryGroupForm" */
            './views/TerritoryGroupForm.container')).TerritoryGroupForm),
    },
    {
        pattern: getPattern('territory-group-edit'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryGroupForm" */
            './views/TerritoryGroupForm.container')).TerritoryGroupForm),
    },
    {
        pattern: getPattern('territory-group-list'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryGroupTable" */
            './views/TerritoryGroupTable.container')).TerritoryGroupTable),
    },


    // Publisher
    {
        pattern: getPattern('publisher-create'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-PublisherForm" */
            './views/PublisherForm.container')).PublisherForm),
    },
    {
        pattern: getPattern('publisher-edit'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-PublisherForm" */
            './views/PublisherForm.container')).PublisherForm),
    },
    {
        pattern: getPattern('publisher-set-password'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-PublisherSetPasswordForm" */
            './views/PublisherSetPasswordForm.container')).PublisherSetPasswordForm),
    },
    {
        pattern: getPattern('publisher-set-roles'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-PublisherSetRolesForm" */
            './views/PublisherSetRolesForm.container')).PublisherSetRolesForm),
    },
    {
        pattern: getPattern('publisher-list'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-PublisherTable" */
            './views/PublisherTable.container')).PublisherTable),
    },


    // Addresses
    {
        pattern: getPattern('address-create'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-AddressForm" */
            './views/AddressForm.container')).AddressForm),
    },
    {
        pattern: getPattern('address-edit'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-AddressForm" */
            './views/AddressForm.container')).AddressForm),
    },
    {
        pattern: getPattern('address-list'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-AddressTable" */
            './views/AddressTable.container')).AddressTable),
    },


    // Territory Assignments
    {
        pattern: getPattern('territory-assignment-list'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryAssignments" */
            './views/TerritoryAssignments.container')).TerritoryAssignments),
    },
    {
        pattern: getPattern('territory-assignment-checkin'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-TerritoryCheckinForm" */
            './views/TerritoryCheckinForm.container')).TerritoryCheckinForm),
    },


    // My Territories
    {
        pattern: getPattern('share-territory'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-ShareTerritory" */
            './views/ShareTerritory.container')).ShareTerritory),
    },
    {
        pattern: getPattern('work-territory'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-WorkTerritory" */
            './views/WorkTerritory.container')).WorkTerritory),
    },
    {
        pattern: getPattern('my-territory-list'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-MyTerritories" */
            './views/MyTerritories.container')).MyTerritories),
    },


    // Audit Log
    {
        pattern: getPattern('audit-log'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-AuditLog" */
            './views/AuditLog.container')).AuditLog),
    },


    // Statistics
    {
        pattern: getPattern('statistics'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-Statistics" */
            './views/Statistics.container')).Statistics),
    },


    // Data Import / Export
    {
        pattern: getPattern('import-address-data'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-ImportAddressData" */
            './views/ImportAddressData.container')).ImportAddressData),
    },


    // Authentication
    {
        pattern: getPattern('login'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-Login" */
            './views/Login.container')).Login),
    },
    {
        pattern: getPattern('update-auth-token'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-UpdateAuthToken" */
            './views/UpdateAuthToken.container')).UpdateAuthToken),
    },


    // Home
    {
        pattern: getPattern('home'),
        Component: loadable(async () => (await import(/* webpackChunkName: "view-Home" */
            './views/Home.container')).Home),
    },
];
