import {IAddressAttemptActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {uniqueByProp} from "../utils/functional";
import {guardUnhandledAction} from "./utils";


const reducerAttempts = function(state = defaultReduxState.attempts, action: IAddressAttemptActions) {
    switch (action.type) {
        case Action.CLEAR_ATTEMPTS:
            return [];

        case Action.APPEND_ATTEMPTS:
            return state.concat(action.payload);

        case Action.SET_ATTEMPTS:
            return action.payload;

        case Action.RECORD_ADDRESS_ATTEMPT:
            return [action.payload].concat(state);

        default:
            guardUnhandledAction(action);
    }

    return state;
};


const reducerDecorator = function(state = defaultReduxState.attempts, action: IAddressAttemptActions) {
    const newState = reducerAttempts(state, action);
    return uniqueByProp(newState, 'id');
};


export default reducerDecorator;
