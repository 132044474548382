import {Location} from "history";
import React from "react";
import {connect} from 'react-redux';
import {IconName} from "@blueprintjs/core";
import * as Sentry from '@sentry/browser';
import {IReduxState} from '../reducers.interfaces';
import {IServiceGroup, IUser, IUserRole} from "../models.interfaces";
import {RoleName} from "../constants";
import {reverse} from "../urls";
import {setNavDrawerOpen} from '../dispatchers';
import {navigateTo, setActiveServiceGroup, logout} from '../actions';
import {NAV_BAR_LINKS} from '../navigation';
import {GlobalNavDrawer as Component} from "./GlobalNavDrawer.component";


interface IOwnProps {
}


interface IReduxProps {
    activeServiceGroupID: number | null;
    user: IUser | null;
    roles: IUserRole[];
    serviceGroups: IServiceGroup[];
    currentLocation: Location | null;
    navDrawerOpen: boolean;
}


interface IProps extends IOwnProps, IReduxProps {
}


interface IState {
    groupSelectorOpen: boolean;
    prefsDialogOpen: boolean;
}


class GlobalNavDrawerContainer extends React.PureComponent<IProps, IState> {
    public state: IState = {
        groupSelectorOpen: false,
        prefsDialogOpen: false,
    };


    private readonly onTitleClick = () => {
        window._paq.push(['trackEvent', 'GlobalNavDrawer', 'onTitleClick']);
        navigateTo(reverse.home());
    }


    private readonly onOpenGroupSelector = () => {
        window._paq.push(['trackEvent', 'GlobalNavDrawer', 'onOpenGroupSelector']);
        this.setState({ groupSelectorOpen: true, });
    }


    private readonly onCloseGroupSelector = () => {
        window._paq.push(['trackEvent', 'GlobalNavDrawer', 'onCloseGroupSelector']);
        this.setState({ groupSelectorOpen: false, });
    }


    private readonly onOpenPrefsDialog = () => {
        window._paq.push(['trackEvent', 'GlobalNavDrawer', 'onOpenPrefsDialog']);
        this.setState({ prefsDialogOpen: true, });
    }


    private readonly onClosePrefsDialog = () => {
        window._paq.push(['trackEvent', 'GlobalNavDrawer', 'onClosePrefsDialog']);
        this.setState({ prefsDialogOpen: false, });
    }


    private readonly onNavigate = (url: string) => {
        setNavDrawerOpen(false);
        navigateTo(url);
    }


    private readonly onCloseNavDrawer = () => {
        window._paq.push(['trackEvent', 'GlobalNavDrawer', 'onCloseNavDrawer']);
        setNavDrawerOpen(false);
    }


    private readonly setActionServiceGroup = (group: IServiceGroup) => {
        window._paq.push(['trackEvent', 'GlobalNavDrawer', 'setActionServiceGroup']);
        if (!group.id) {
            throw new Error('Can not activate transient service group');
        }
        this.onCloseGroupSelector();
        setActiveServiceGroup(this.props.activeServiceGroupID, group.id)
            .then(() => {
                navigateTo(reverse.home());
            });
    }


    private readonly onSubmitFeedback = () => {
        window._paq.push(['trackEvent', 'GlobalNavDrawer', 'onSubmitFeedback']);
        const eventID = Sentry.captureMessage('Send Feedback');
        Sentry.showReportDialog({
            eventId: eventID,
            user: {
                name: this.props.user
                    ? `${this.props.user.first_name} ${this.props.user.last_name}`
                    : undefined,
                email: this.props.user
                    ? this.props.user.email
                    : undefined,
            },
            title: "Submit Feedback",
            subtitle: "Help BaruchApp get better.",
            subtitle2: "If you have an idea, suggestion, or issue with BaruchApp, let us know how we can improve.",
            labelComments: "Feedback",
            labelClose: "Cancel",
            labelSubmit: "Submit Feedback",
        });
    }


    private hasPerm (roleName: RoleName) {
        const role = this.props.roles
            .find((r) => {
                return r.role_type.name === roleName;
            });
        return role;
    }


    private getNavigationLinks () {
        // Filter out links the user is not permitted to see
        const self = this;
        const groups = NAV_BAR_LINKS
            .map((origLinkGroup) => {
                return {
                    ...origLinkGroup,
                    links: origLinkGroup.links
                        .filter((link) => {
                            return self.hasPerm(link.role);
                        }),
                };
            })
            .filter((linkGroup) => {
                return linkGroup.links.length > 0;
            });
        return groups;
    }


    render () {
        const links = this.getNavigationLinks();
        const helpMenuLinks: Array<{ icon: IconName; label: string; link: string; }> = [
            {
                icon: 'shield',
                label: 'Privacy Policy',
                link: process.env.PRIVACY_POLICY_LINK || '',
            },
            {
                icon: 'info-sign',
                label: 'Terms of Service',
                link: process.env.TOS_LINK || '',
            },
        ];
        return (
            <Component
                activeServiceGroupID={this.props.activeServiceGroupID}
                currentLocation={this.props.currentLocation}
                user={this.props.user}
                serviceGroups={this.props.serviceGroups}
                links={links}
                navDrawerOpen={this.props.navDrawerOpen}
                groupSelectorOpen={this.state.groupSelectorOpen}
                prefsDialogOpen={this.state.prefsDialogOpen}
                helpMenuLinks={helpMenuLinks}
                onTitleClick={this.onTitleClick}
                onOpenGroupSelector={this.onOpenGroupSelector}
                onCloseGroupSelector={this.onCloseGroupSelector}
                onOpenPrefsDialog={this.onOpenPrefsDialog}
                onClosePrefsDialog={this.onClosePrefsDialog}
                onNavigate={this.onNavigate}
                onCloseNavDrawer={this.onCloseNavDrawer}
                onLogout={logout}
                setActiveServiceGroup={this.setActionServiceGroup}
                onSubmitFeedback={this.onSubmitFeedback}
            />
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IProps => {
    return {
        activeServiceGroupID: state.permissions.active_service_group,
        user: state.permissions.user,
        serviceGroups: state.permissions.service_groups,
        roles: state.permissions.roles,
        currentLocation: (state.router ? state.router.location : null),
        navDrawerOpen: state.ui.navDrawerOpen,
        ...ownProps,
    };
};

export const GlobalNavDrawer = connect(mapStateToProps)(GlobalNavDrawerContainer);
