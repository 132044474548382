import React from 'react';
import {ISelectOption} from '../../forms.interfaces';
import {Select} from '../elements/Select';
import {FormButtons} from '../elements/FormButtons';
import {FormDialog} from '../wrappers/FormDialog';


interface IProps {
    options: ISelectOption[];
    isOpen: boolean;
    groupID: number | null;
    onSelect: (e: React.FormEvent<HTMLSelectElement>) => void;
    onCancel: () => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

interface IState {
}

export class ServiceGroupSelector extends React.PureComponent<IProps, IState> {

    render () {
        return (
            <FormDialog
                icon='cog'
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onCancel}
                title="Select Service Group"
            >
                <form onSubmit={this.props.onSubmit}>
                    <Select
                        classPrefix='service-group-selector'
                        name='groupID'
                        value={`${this.props.groupID}`}
                        label={"Service Group"}
                        helpText={"Select a service group to view and manage the territories in that group."}
                        options={this.props.options}
                        onChange={this.props.onSelect}
                        icon='cog'
                        inline={false}
                    />
                    <FormButtons
                        cancel={{
                            text: "Cancel",
                            icon: 'undo',
                            onClick: () => { this.props.onCancel(); },
                        }}
                        submit={{
                            text: "Save",
                            icon: 'floppy-disk',
                        }}
                    />
                </form>
            </FormDialog>
        );
    }
}
