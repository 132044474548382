import {IAssignmentActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {ITerritoryAssignment} from "../models.interfaces";
import {guardUnhandledAction} from "./utils";

const setAssignments = function(assignments: ITerritoryAssignment[]) {
    assignments.sort((a, b) => {
        const outA = a.date_out;
        const outB = b.date_out;
        if (outA < outB) {
            return -1;
        }
        if (outA > outB) {
            return 1;
        }

        return 0;
    });
    return assignments;
};

const updateAssignment = function(state: ITerritoryAssignment[], assignment: ITerritoryAssignment) {
    const assignments = state.filter((a) => {
        return a.id !== assignment.id;
    });
    assignments.push(assignment);
    return setAssignments(assignments);
};

const deleteAssignment = function(state: ITerritoryAssignment[], assignmentID: number) {
    const assignments = state.filter((a) => {
        return a.id !== assignmentID;
    });
    return setAssignments(assignments);
};

const reducerAssignments = function(state = defaultReduxState.assignments, action: IAssignmentActions) {
    switch (action.type) {
        case Action.SET_ASSIGNMENTS:
            return setAssignments(action.payload);

        case Action.UPDATE_ASSIGNMENT:
            return updateAssignment(state, action.payload);

        case Action.DELETE_ASSIGNMENT:
            return deleteAssignment(state, action.payload.id);

        default:
            guardUnhandledAction(action);
    }

    return state;
};

export default reducerAssignments;
