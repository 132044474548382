import {IUIActions} from "../actions.interfaces";
import {Action} from "../constants";
import {IReduxState} from "../reducers.interfaces";
import {defaultReduxState} from '../defaults';
import {guardUnhandledAction} from './utils';


const reducerUI = function(state: IReduxState["ui"] = defaultReduxState.ui, action: IUIActions) {
    switch (action.type) {
        case Action.SET_LAYOUT:
            return {...state, layout: action.payload, };

        case Action.SET_NAV_DRAWER_OPEN:
            return {...state, navDrawerOpen: action.payload, };

        case Action.SET_IS_ONLINE:
            return {...state, isOnline: action.payload, };

        case Action.UPDATE_NAV_BAR:
            return {...state, ...action.payload, };

        case Action.SET_TERRITORY_GROUP_FILTER:
            return {...state, filteredTerritoryGroupID: action.payload, };

        default:
            guardUnhandledAction(action);
    }

    return state;
};

export default reducerUI;
