import React from 'react';
import {connect} from 'react-redux';
import {IUserPrefs} from '../../models.interfaces';
import {IReduxState} from '../../reducers.interfaces';
import {setPrefs} from '../../dispatchers';
import {UserPrefsDialog as Component} from './UserPrefsDialog.component';
import {sortByOptions, distanceUnitOptions, mapAppOptions} from '../../constants';


interface IOwnProps {
    isOpen: boolean;
    onClose: () => void;
}

interface IReduxProps {
    prefs: IUserPrefs;
}

interface IProps extends IOwnProps, IReduxProps {}


interface IState {
}


class UserPrefsDialogContainer extends React.PureComponent<IProps, IState> {


    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.onClose();
    }


    private readonly onSelectSort: React.FormEventHandler<HTMLSelectElement> = (event) => {
        setPrefs(null, {
            workTerrAddrSort: (event.currentTarget.value as IUserPrefs['workTerrAddrSort']),
        });
    }


    private readonly onSelectDistance: React.FormEventHandler<HTMLSelectElement> = (event) => {
        setPrefs(null, {
            distanceUnit: (event.currentTarget.value as IUserPrefs['distanceUnit']),
        });
    }


    private readonly onSelectMapApp: React.FormEventHandler<HTMLSelectElement> = (event) => {
        setPrefs(null, {
            mapApp: (event.currentTarget.value as IUserPrefs['mapApp']),
        });
    }


    render () {
        return (
            <Component
                isOpen={this.props.isOpen}
                prefs={this.props.prefs}
                sortByOptions={sortByOptions}
                distanceUnitOptions={distanceUnitOptions}
                mapAppOptions={mapAppOptions}
                onSelectSort={this.onSelectSort}
                onSelectDistance={this.onSelectDistance}
                onSelectMapApp={this.onSelectMapApp}
                onClose={this.props.onClose}
                onSubmit={this.onSubmit}
            />
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IProps => {
    return {
        prefs: state.prefs,
        ...ownProps,
    };
};

export const UserPrefsDialog = connect(mapStateToProps)(UserPrefsDialogContainer);
