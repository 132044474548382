import {ITerritoryGroupActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {ITerritoryGroup} from "../models.interfaces";
import {guardUnhandledAction} from "./utils";
import {getCollator} from "../utils/sorting";

const setTerritoryGroups = function(groups: ITerritoryGroup[]) {
    // Sort alphabetically by name
    const collator = getCollator();
    groups.sort((a, b) => {
        return collator.compare(a.name, b.name);
    });
    return ([] as ITerritoryGroup[]).concat(groups);
};

const updateTerritoryGroup = function(state: ITerritoryGroup[], group: ITerritoryGroup) {
    const groups = state.filter((g) => {
        return g.id !== group.id;
    });
    groups.push(group);
    return setTerritoryGroups(groups);
};

const deleteTerritoryGroup = function(state: ITerritoryGroup[], groupID: number) {
    const groups = state.filter((g) => {
        return g.id !== groupID;
    });
    return setTerritoryGroups(groups);
};

const reducerTerritoryGroups = function(state = defaultReduxState.territory_groups, action: ITerritoryGroupActions) {
    switch (action.type) {
        case Action.SET_TERRITORY_GROUPS:
            return setTerritoryGroups(action.payload);

        case Action.UPDATE_TERRITORY_GROUP:
            return updateTerritoryGroup(state, action.payload);

        case Action.DELETE_TERRITORY_GROUP:
            return deleteTerritoryGroup(state, action.payload.id);

        default:
            guardUnhandledAction(action);
    }

    return state;
};

export default reducerTerritoryGroups;
