import React from "react";
import classNames from 'classnames';
import {Swipeable, EventData as SwipeEventData} from 'react-swipeable';
import {GlobalNavBar} from "./GlobalNavBar.container";
import {GlobalNavDrawer} from "./GlobalNavDrawer.container";

interface IProps {
    navDrawerOpen: boolean;
    onSwipedLeft?: (e: SwipeEventData) => void;
    onSwipedRight?: (e: SwipeEventData) => void;
}

interface IState {}

export class Root extends React.PureComponent<IProps, IState> {

    render () {
        const pageWrapperClasses = classNames({
            'clearfix': true,
            'page-wrapper': true,
            'page-wrapper--drawer-open': this.props.navDrawerOpen,
        });
        return (
            <Swipeable
                className="swipeable clearfix"
                delta={50}
                {...this.props}
            >
                <GlobalNavBar />
                <GlobalNavDrawer />
                <div id="page-wrapper" className={pageWrapperClasses}>
                    {this.props.children}
                </div>
            </Swipeable>
        );
    }
}
