import { ICoordinate } from "../models.interfaces";

export type IBoundingBox = [ICoordinate, ICoordinate, ICoordinate, ICoordinate];

/**
 * Convert degrees to radians
 */
export const deg2rad = function(degrees: number) {
    return (Math.PI * degrees) / 180.0;
};

/**
 * Convert radians to degrees
 */
export const rad2deg = function(radians: number) {
    return (180.0 * radians) / Math.PI;
};

/**
 * Semi-axes of WGS-84 geoidal reference
 */
export const WGS84_a = 6378137.0;
export const WGS84_b = 6356752.3;

/**
 * Earth radius (in meters) at a given latitude, according to the WGS-84 ellipsoid.
 */
export const WGS84EarthRadius = function(lat: number) {
    // http://en.wikipedia.org/wiki/Earth_radius
    const An = WGS84_a * WGS84_a * Math.cos(lat);
    const Bn = WGS84_b * WGS84_b * Math.sin(lat);
    const Ad = WGS84_a * Math.cos(lat);
    const Bd = WGS84_b * Math.sin(lat);
    return Math.sqrt((An * An + Bn * Bn) / (Ad * Ad + Bd * Bd));
};

/**
 * Bounding box surrounding the point at given coordinates,
 * assuming local approximation of Earth surface as a sphere
 * of radius given by WGS84.
 */
export const geoBoundingBox = function(lngDegrees: number, latDegrees: number, halfSideKM: number) {
    const latRads = deg2rad(latDegrees);
    const lngRads = deg2rad(lngDegrees);
    const halfSide = 1000 * halfSideKM;

    // Radius of Earth at given latitude
    const radius = WGS84EarthRadius(latRads);

    // Radius of the parallel at given latitude
    const pradius = radius * Math.cos(latRads);

    const latMin = parseFloat(rad2deg(latRads - halfSide / radius).toFixed(5));
    const latMax = parseFloat(rad2deg(latRads + halfSide / radius).toFixed(5));
    const lngMin = parseFloat(rad2deg(lngRads - halfSide / pradius).toFixed(5));
    const lngMax = parseFloat(rad2deg(lngRads + halfSide / pradius).toFixed(5));

    const aa: ICoordinate = [lngMin, latMax];
    const ab: ICoordinate = [lngMin, latMin];
    const ba: ICoordinate = [lngMax, latMin];
    const bb: ICoordinate = [lngMax, latMax];
    const box: IBoundingBox = [aa, ab, ba, bb];
    return box;
};


/**
 * See https://stackoverflow.com/a/20811670/162220
 */
const filterOutliers = function(someArray: number[]) {
    // Algorithm only works for arrays longer than 4 elements
    if (someArray.length <= 4) {
        return someArray;
    }

    // Copy the values, rather than operating on references to existing values
    const values = [...someArray];

    // Then sort
    values.sort((a, b) => {
        return a - b;
    });

    // Then find a generous IQR. This is generous because if (values.length / 4)
    // is not an int, then really you should average the two elements on either
    // side to find q1.
    const q1 = values[Math.floor((values.length / 4))];
    // Likewise for q3.
    const q3 = values[Math.ceil((values.length * (3 / 4)))];
    const iqr = q3 - q1;

    // Then find min and max values
    const maxValue = q3 + iqr * 1.5;
    const minValue = q1 - iqr * 1.5;

    // Then filter anything beyond or beneath these values.
    const filteredValues = values.filter((x) => {
        return (x <= maxValue) && (x >= minValue);
    });

    // Then return
    return filteredValues;
};


/**
 * Get the geographic center of the given collection of points
 */
export const getGeographicCenter = function(points: ICoordinate[]) {
    // Get list of all longitudes and latitudes
    const lngs = filterOutliers(points.map((coord) => {
        return coord[0];
    }));
    const lats = filterOutliers(points.map((coord) => {
        return coord[1];
    }));

    // Find coordinate extremes
    const minLng = Math.max(-180, Math.min(...lngs));
    const maxLng = Math.min( 180, Math.max(...lngs));
    const minLat = Math.max( -90, Math.min(...lats));
    const maxLat = Math.min(  90, Math.max(...lats));

    // Average the extremes to find the center
    const centerLng = (minLng + maxLng) / 2;
    const centerLat = (minLat + maxLat) / 2;
    return [centerLng, centerLat] as ICoordinate;
};

/**
 * Calculate the distance between 2 coordinate pairs in meters.
 */
export const calculateDistance = function(from: ICoordinate, to: ICoordinate) {
    // Get the radius of Earth at given latitude
    const avgLatDeg = (from[1] + to[1]) / 2;
    const avgLatRads = deg2rad(avgLatDeg);
    const radius = WGS84EarthRadius(avgLatRads);
    const dLat = deg2rad(to[1] - from[1]);
    const dLon = deg2rad(to[0] - from[0]);
    const lat1 = deg2rad(from[1]);
    const lat2 = deg2rad(to[1]);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = radius * c;
    return d;
};
