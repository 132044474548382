import {
    Alignment,
    Navbar,
    NavbarGroup,
    NavbarHeading,
    NavbarDivider,
    Button,
} from "@blueprintjs/core";
import classNames from 'classnames';
import React from "react";


interface IProps {
    navDrawerOpen: boolean;
    isOnline: boolean;
    showBackButton: boolean;
    getNavBarTitle: () => string | JSX.Element | null;
    getNavBarContentLeft: () => (JSX.Element | JSX.Element[] | null);
    getNavBarContentRight: () => (JSX.Element | JSX.Element[] | null);
    onBack: () => void;
    onToggleNavDrawer: () => void;
}


interface IState {
}


export class GlobalNavBar extends React.PureComponent<IProps, IState> {

    private getContentLeft () {
        const content = this.props.getNavBarContentLeft();
        if (!content) {
            return null;
        }
        return (
            <>
                <NavbarDivider />
                {content}
            </>
        );
    }


    private getContentRight () {
        const content = this.props.getNavBarContentRight();
        if (!content) {
            return null;
        }
        return (
            <NavbarGroup align={Alignment.RIGHT}>
                {content}
            </NavbarGroup>
        );
    }


    render () {
        const classes = classNames({
            'global-nav-bar': true,
            'global-nav-bar--drawer-open': this.props.navDrawerOpen,
            'global-nav-bar--online': this.props.isOnline,
            'global-nav-bar--offline': !this.props.isOnline,
        });
        const navBarTitle = this.props.getNavBarTitle();
        window.document.title = `${navBarTitle} | Baruch Territory Manager`;
        return (
            <Navbar fixedToTop={true} className={classes}>
                <NavbarGroup align={Alignment.LEFT}>
                    <Button
                        className='global-nav-bar__menu-toggle'
                        minimal={true}
                        icon={this.props.showBackButton ? 'chevron-left' : 'menu'}
                        onClick={this.props.showBackButton ? this.props.onBack : this.props.onToggleNavDrawer}
                    />
                    <NavbarHeading>
                        {navBarTitle}
                    </NavbarHeading>
                    {this.getContentLeft()}
                </NavbarGroup>
                {this.getContentRight()}
            </Navbar>
        );
    }
}
