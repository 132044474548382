import {
    IActionResetStore,
    IActionSetCurrentUser,
    IActionSetLayout,
    IActionSetNavDrawerOpen,
    IActionSetIsOnline,
    IActionUpdateNavBar,
    IActionSetTerritoryGroupFilter,
    IActionClearAddresses,
    IActionAppendAddresses,
    IActionSetAddresses,
    IActionClearAttempts,
    IActionAppendAttempts,
    IActionSetAttempts,
    IActionSetReduxEventMeta,
    IActionSetPref,
} from "./actions.interfaces";
import {Layout, Action} from "./constants";
import {IAddressAttempt, IReduxEventMeta, IUserPrefs, IUser} from "./models.interfaces";
import {store} from "./store";


export const resetStore = function() {
    store.dispatch<IActionResetStore>({
        type: Action.RESET_STORE,
    });
};


export const setCurrentUser = function(user: IUser | null) {
    store.dispatch<IActionSetCurrentUser>({
        type: Action.SET_CURRENT_USER,
        meta: {
            service_group: null,
        },
        payload: user,
    });
};


export const setLayout = function(layout: Layout) {
    store.dispatch<IActionSetLayout>({
        type: Action.SET_LAYOUT,
        payload: layout,
    });
};


export const setNavDrawerOpen = function(isOpen: boolean) {
    store.dispatch<IActionSetNavDrawerOpen>({
        type: Action.SET_NAV_DRAWER_OPEN,
        payload: isOpen,
    });
};


export const setIsOnline = function(isOnline: boolean) {
    store.dispatch<IActionSetIsOnline>({
        type: Action.SET_IS_ONLINE,
        payload: isOnline,
    });
};


export const updateNavBar = function(payload: IActionUpdateNavBar['payload']) {
    store.dispatch<IActionUpdateNavBar>({
        type: Action.UPDATE_NAV_BAR,
        payload: payload,
    });
};


export const setTerritoryGroupFilter = function(payload: number | null) {
    store.dispatch<IActionSetTerritoryGroupFilter>({
        type: Action.SET_TERRITORY_GROUP_FILTER,
        payload: payload,
    });
};


export const setReduxEventMeta = function(activeServiceGroupID: number | null, data: IReduxEventMeta[]) {
    store.dispatch<IActionSetReduxEventMeta>({
        type: Action.SET_REDUX_EVENT_META,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: data,
    });
};


export const clearAddresses = function(activeServiceGroupID: number | null) {
    store.dispatch<IActionClearAddresses>({
        type: Action.CLEAR_ADDRESSES,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: null,
    });
};


export const appendAddresses = function(activeServiceGroupID: number | null, data: IActionAppendAddresses["payload"]) {
    store.dispatch<IActionAppendAddresses>({
        type: Action.APPEND_ADDRESSES,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: data,
    });
};


export const setAddresses = function(activeServiceGroupID: number | null, data: IActionSetAddresses["payload"]) {
    store.dispatch<IActionSetAddresses>({
        type: Action.SET_ADDRESSES,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: data,
    });
};


export const clearAttempts = function(activeServiceGroupID: number | null) {
    store.dispatch<IActionClearAttempts>({
        type: Action.CLEAR_ATTEMPTS,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: null,
    });
};


export const appendAttempts = function(activeServiceGroupID: number | null, data: IActionAppendAttempts["payload"]) {
    store.dispatch<IActionAppendAttempts>({
        type: Action.APPEND_ATTEMPTS,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: data,
    });
};


export const setAttempts = function(activeServiceGroupID: number | null, attempts: IAddressAttempt[]) {
    store.dispatch<IActionSetAttempts>({
        type: Action.SET_ATTEMPTS,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: attempts,
    });
};


export const setPrefs = function(activeServiceGroupID: number | null, prefs: Partial<IUserPrefs>) {
    store.dispatch<IActionSetPref>({
        type: Action.SET_PREF,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: prefs,
    });
};
