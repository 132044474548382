import {IPublisherActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {IPublisher} from "../models.interfaces";
import {guardUnhandledAction} from "./utils";
import {sortPublishers} from "../utils/sorting";

const setPublishers = function(publishers: IPublisher[]) {
    return sortPublishers(publishers);
};

const updatePublisher = function(state: IPublisher[], publisher: IPublisher) {
    const publishers = state.filter((p) => {
        return p.id !== publisher.id;
    });
    publishers.push(publisher);
    return setPublishers(publishers);
};

const deletePublisher = function(state: IPublisher[], publisherID: number) {
    const publisher = state.find((p) => {
        return p.id === publisherID;
    });
    if (!publisher) {
        return state;
    }
    return updatePublisher(state, {
        ...publisher,
        is_active: false,
    });
};

const reactivatePublisher = function(state: IPublisher[], publisherID: number) {
    const publisher = state.find((p) => {
        return p.id === publisherID;
    });
    if (!publisher) {
        return state;
    }
    return updatePublisher(state, {
        ...publisher,
        is_active: true,
    });
};

const reducerPermissions = function(state = defaultReduxState.publishers, action: IPublisherActions) {
    switch (action.type) {
        case Action.SET_PUBLISHERS:
            return setPublishers(action.payload);

        case Action.UPDATE_PUBLISHER:
            return updatePublisher(state, action.payload);

        case Action.SET_PUBLISHER_PASSWORD:
            return state;

        case Action.SET_PUBLISHER_ROLES:
            return state;  // No-Op

        case Action.DELETE_PUBLISHER:
            return deletePublisher(state, action.payload.id);

        case Action.REACTIVATE_PUBLISHER:
            return reactivatePublisher(state, action.payload.id);

        default:
            guardUnhandledAction(action);
    }

    return state;
};

export default reducerPermissions;
