import React from 'react';
import {IAddressSortOption, IDistanceUnit, IMapApp, IUserPrefs} from '../../models.interfaces';
import {ITypedSelectOption} from '../../forms.interfaces';
import {Select} from '../elements/Select';
import {FormButtons} from '../elements/FormButtons';
import {FormDialog} from '../wrappers/FormDialog';


interface IProps {
    isOpen: boolean;
    prefs: IUserPrefs;
    sortByOptions: ITypedSelectOption<IAddressSortOption>[];
    distanceUnitOptions: ITypedSelectOption<IDistanceUnit>[];
    mapAppOptions: ITypedSelectOption<IMapApp>[];
    onSelectSort: (e: React.FormEvent<HTMLSelectElement>) => void;
    onSelectDistance: (e: React.FormEvent<HTMLSelectElement>) => void;
    onSelectMapApp: (e: React.FormEvent<HTMLSelectElement>) => void;
    onClose: () => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

interface IState {
}


export class UserPrefsDialog extends React.PureComponent<IProps, IState> {

    render () {
        return (
            <FormDialog
                icon='cog'
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                title="Preferences"
            >
                <form onSubmit={this.props.onSubmit}>
                    <Select
                        classPrefix='user-prefs-dialog'
                        name='address-sort-by'
                        value={`${this.props.prefs.workTerrAddrSort}`}
                        label={"Address Sort Order"}
                        helpText={"Change how the addresses are sorted when working a territory."}
                        options={this.props.sortByOptions}
                        onChange={this.props.onSelectSort}
                        icon='sort'
                        inline={false}
                    />
                    <Select
                        classPrefix='user-prefs-dialog'
                        name='address-distance-unit'
                        value={`${this.props.prefs.distanceUnit}`}
                        label={"Unit of Distance"}
                        helpText={"Change the unit used for displaying distances."}
                        options={this.props.distanceUnitOptions}
                        onChange={this.props.onSelectDistance}
                        icon='arrows-horizontal'
                        inline={false}
                    />
                    <Select
                        classPrefix='user-prefs-dialog'
                        name='map-app'
                        value={`${this.props.prefs.mapApp}`}
                        label={"Preferred Map"}
                        helpText={"Change what app is used to open address links and provide directions."}
                        options={this.props.mapAppOptions}
                        onChange={this.props.onSelectMapApp}
                        icon='send-to-map'
                        inline={false}
                    />
                    <FormButtons
                        submit={{
                            text: "OK",
                            icon: 'floppy-disk',
                        }}
                    />
                </form>
            </FormDialog>
        );
    }
}
