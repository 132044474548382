import {ISelectOption} from "./forms.interfaces";
import {
    check,
    CMSBlogPostAPIResponse,
    User,
    RolesAPIResponse,
    ServiceGroupStatistics,
    ServiceGroupAdvancedStatistics,
    AddressHeatmapPoints,
    AddressAPIResponse,
} from "./models";
import {IAddrSearchFilters} from './models.interfaces';
import {DRFOptionsResponse} from "./drf.codecs";
import {getAPIClient} from "./utils/ajax";



export const getCurrentUser = async () => {
    const api = await getAPIClient();
    const resp = await api.get(`/api/login/`);
    if (!resp.body) {
        return null;
    }
    return check(User.decode(resp.body));
};


export const getPublisherRoles = async function(serviceGroupID: number, publisherID: number) {
    const api = await getAPIClient();
    const resp = await api.get(`/api/servicegroups/${serviceGroupID}/publishers/${publisherID}/roles/`);
    return check(RolesAPIResponse.decode(resp.body));
};


export const listAddressStateChoices = async function(serviceGroupID: number): Promise<ISelectOption[]> {
    const api = await getAPIClient();
    const resp = await api.options(`/api/servicegroups/${serviceGroupID}/addresses/`);
    const options = check(DRFOptionsResponse.decode(resp.body));
    if (!options || !options.actions.POST.state || options.actions.POST.state.type !== "choice") {
        return [];
    }
    return options.actions.POST.state.choices.map(
        (opt): ISelectOption => {
            return {
                value: opt.value,
                label: opt.display_name,
            };
        },
    );
};


export const getStatistics = async function(serviceGroupID: number) {
    const api = await getAPIClient();
    const resp = await api.get(`/api/servicegroups/${serviceGroupID}/stats/`);
    return check(ServiceGroupStatistics.decode(resp.body));
};


export const getAdvancedStatistics = async function(serviceGroupID: number) {
    const api = await getAPIClient();
    const resp = await api.get(`/api/servicegroups/${serviceGroupID}/advanced_stats/`);
    return check(ServiceGroupAdvancedStatistics.decode(resp.body));
};


export const getRecentBlogPosts = async function(limit: number = 5) {
    const api = await getAPIClient();
    const resp = await api
        .get(`/api/cms/v2/pages/`)
        .query({
            type: 'cms.BlogPost',
            order: '-posted_datetime',
            fields: '*',
            limit: limit,
        });
    return check(CMSBlogPostAPIResponse.decode(resp.body));
};


export const getAddressHeatmapPoints = async function(serviceGroupID: number) {
    const api = await getAPIClient();
    const resp = await api.get(`/api/servicegroups/${serviceGroupID}/addresses/heatmap/`);
    return check(AddressHeatmapPoints.decode(resp.body));
};


export const listAddresses = async function(serviceGroupID: number, pageNum = 1, filters?: IAddrSearchFilters | null) {
    const url = `/api/servicegroups/${serviceGroupID}/addresses/`;
    const api = await getAPIClient();
    const resp = await api
        .get(url)
        .query({
            page: pageNum,
            search: filters ? filters.search : '',
            withTags: filters ? filters.withTags : [],
            withoutTags: filters ? filters.withoutTags : [],
            territory: filters ? filters.territory : '',
        });
    return check(AddressAPIResponse.decode(resp.body));
};
