import {IAddressActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {IAddress, IAddressNotes} from "../models.interfaces";
import {IReduxState} from "../reducers.interfaces";
import {uniqueByProp} from "../utils/functional";
import {guardUnhandledAction} from "./utils";


const setAddresses = function(hasPrev: boolean, hasNext: boolean, pageNum: number, total: number, items: IAddress[]): IReduxState["addresses"] {
    return {
        hasPrev: hasPrev,
        hasNext: hasNext,
        pageNum: pageNum,
        totalItems: total,
        items: items,
    };
};


const updateAddresses = function(state: IReduxState["addresses"], address: IAddress): IReduxState["addresses"] {
    const addresses = state.items.map((a) => {
        return a.id === address.id ? address : a;
    });
    return {
        ...state,
        items: addresses,
    };
};


const updateAddressNotes = function(state: IReduxState["addresses"], notes: IAddressNotes): IReduxState["addresses"] {
    const address = state.items.find((a) => {
        return a.id === notes.id;
    });
    if (!address) {
        return state;
    }
    const updatedAddress = {
        ...address,
        ...notes,
    };
    return updateAddresses(state, updatedAddress);
};


const deleteAddress = function(state: IReduxState["addresses"], addressID: number): IReduxState["addresses"] {
    const addresses = state.items.filter((a) => {
        return a.id !== addressID;
    });
    return {
        ...state,
        items: addresses,
    };
};


const reducerAddresses = function(state = defaultReduxState.addresses, action: IAddressActions) {
    switch (action.type) {
        case Action.CLEAR_ADDRESSES:
            return {
                ...defaultReduxState.addresses,
            };

        case Action.APPEND_ADDRESSES:
            return {
                ...state,
                items: state.items.concat(action.payload),
            };

        case Action.SET_ADDRESSES:
            return setAddresses(
                !!action.payload.previous,
                !!action.payload.next,
                action.payload.pageNum,
                action.payload.count,
                action.payload.results,
            );

        case Action.AUTOASSIGN_ADDRESSES:
            return state;

        case Action.UPDATE_ADDRESS:
            return updateAddresses(state, action.payload);

        case Action.UPDATE_ADDRESS_NOTES:
            return updateAddressNotes(state, action.payload);

        case Action.GEOCODE_ADDRESS:
            return state;

        case Action.DELETE_ADDRESS:
            return deleteAddress(state, action.payload.id);

        default:
            guardUnhandledAction(action);
    }

    return state;
};


const reducerDecorator = function(state = defaultReduxState.addresses, action: IAddressActions) {
    const newState = reducerAddresses(state, action);
    newState.items = uniqueByProp(newState.items, 'id');
    return newState;
};


export default reducerDecorator;
