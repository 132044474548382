import * as Sentry from '@sentry/browser';
import {IPermissionActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {IServiceGroup, IUser} from "../models.interfaces";
import {IReduxPermissions} from "../reducers.interfaces";
import {guardUnhandledAction} from "./utils";

const setCurrentUser = function(state: IReduxPermissions, user: IUser | null) {
    // Set user ID for analytics
    if (user && user.id && typeof(window._paq) !== "undefined") {
        window._paq.push(['setUserId', `${user.id}`]);
    }
    // Set user ID for sentry
    Sentry.configureScope((scope) => {
        scope.setUser({
            ...user,
            id: (user && user.id)
                ? `${user.id}`
                : undefined,
        });
    });
    state = {
        ...state,
        user: user,
    };
    return state;
};

const setServiceGroups = function(state: IReduxPermissions, groups: IServiceGroup[]) {
    state = {
        ...state,
        service_groups: groups,
    };

    const validIDs = state.service_groups.map((group) => {
        return group.id;
    });

    if (!state.active_service_group || validIDs.indexOf(state.active_service_group) === -1) {
        state.active_service_group = validIDs[0];
    }

    return state;
};

const setActiveGroup = function(state: IReduxPermissions, id: number) {
    state = { ...state };

    const validSlugs = state.service_groups.map((group) => {
        return group.id;
    });

    if (validSlugs.indexOf(id) !== -1) {
        state.active_service_group = id;
    } else {
        throw new Error("Can not select slug which isn't valid for any loaded group");
    }

    return state;
};

const reducerPermissions = function(state = defaultReduxState.permissions, action: IPermissionActions) {
    switch (action.type) {
        case Action.SET_REDUX_EVENT_META:
            return { ...state, event_metadata: action.payload, };

        case Action.SET_CURRENT_USER:
            return setCurrentUser(state, action.payload);

        case Action.SET_SERVICE_GROUPS:
            return setServiceGroups(state, action.payload);

        case Action.SET_ACTIVE_GROUP:
            if (action.payload.id) {
                return setActiveGroup(state, action.payload.id);
            }
            break;

        case Action.SET_USER_ROLES:
            return { ...state, roles: action.payload, };

        default:
            guardUnhandledAction(action);
    }

    return state;
};

export default reducerPermissions;
