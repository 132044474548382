import {ITerritoryActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {ITerritory} from "../models.interfaces";
import {guardUnhandledAction} from "./utils";
import {sortTerritories} from "../utils/sorting";

const setTerritories = function(territories: ITerritory[]) {
    return sortTerritories(territories);
};

const updateTerritory = function(state: ITerritory[], territory: ITerritory) {
    const territories = state.filter((t) => {
        return t.id !== territory.id;
    });
    territories.push(territory);
    return setTerritories(territories);
};

const deleteTerritory = function(state: ITerritory[], territoryID: number) {
    const territories = state.filter((t) => {
        return t.id !== territoryID;
    });
    return setTerritories(territories);
};

const reducerTerritories = function(state = defaultReduxState.territories, action: ITerritoryActions) {
    switch (action.type) {
        case Action.SET_TERRITORIES:
            return setTerritories(action.payload);

        case Action.UPDATE_TERRITORY:
            return updateTerritory(state, action.payload);

        case Action.DELETE_TERRITORY:
            return deleteTerritory(state, action.payload.id);

        case Action.CHECKOUT_TERRITORY:
            return state;

        case Action.ASSIGN_TERRITORY:
            return state;

        default:
            guardUnhandledAction(action);
    }

    return state;
};

export default reducerTerritories;
