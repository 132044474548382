import {ITagActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {IAddressTag, IResultTag} from "../models.interfaces";
import {guardUnhandledAction} from "./utils";
import {getCollator} from "../utils/sorting";

const setTags = function<T extends (IAddressTag | IResultTag)>(tags: T[]) {
    // Sort alphabetically by name
    const collator = getCollator();
    tags.sort((a, b) => {
        return collator.compare(a.name, b.name);
    });
    return tags;
};

const reducerTags = function(state = defaultReduxState.tags, action: ITagActions) {
    switch (action.type) {
        case Action.SET_ADDRESS_TAGS:
            return { ...state, address: setTags(action.payload) };

        case Action.SET_RESULT_TAGS:
            return { ...state, result: setTags(action.payload) };

        default:
            guardUnhandledAction(action);
    }

    return state;
};

export default reducerTags;
