import * as t from 'io-ts';
import {Plugins} from '@capacitor/core';
import {routerMiddleware} from 'connected-react-router';
import {applyMiddleware, createStore} from "redux";
import {persistStore, persistReducer, createTransform} from 'redux-persist';
import {composeWithDevTools} from 'redux-devtools-extension';
import {history} from './history';
import {defaultReduxState} from './defaults';
import reducer from "./reducers";
import {
    check,
    nullable,
    Address,
    AddressAttempt,
    Publisher,
    ReduxEventMeta,
    ServiceGroup,
    UserRole,
    AddressTag,
    ResultTag,
    Territory,
    TerritoryAssignment,
    TerritoryGroup,
    User,
    UserPrefs,
} from "./models";

const {Storage} = Plugins;


// Build Redux middleware
const routerMD = routerMiddleware(history);
const middleware = applyMiddleware(routerMD);
const devToolsMiddleware: typeof middleware = composeWithDevTools(middleware);


const storage = {
    setItem: async (key: string, value: string) => {
        return Storage.set({ key, value, });
    },
    getItem: async (key: string) => {
        return (await Storage.get({ key, })).value;
    },
    removeItem: async (key: string) => {
        return Storage.remove({ key, });
    },
    keys: async () => {
        return (await Storage.keys()).keys;
    },
    clear: async () => {
        return Storage.clear();
    },
};


// Codecs used to decode state from redux-persist
const Permissions = t.interface({
    event_metadata: t.array(ReduxEventMeta),
    user: nullable(User),
    roles: t.array(UserRole),
    service_groups: t.array(ServiceGroup),
    active_service_group: t.number,
});
const Tags = t.interface({
    address: t.array(AddressTag),
    result: t.array(ResultTag),
});
const Addresses = t.interface({
    hasPrev: t.boolean,
    hasNext: t.boolean,
    pageNum: t.number,
    totalItems: t.number,
    items: t.array(Address),
});
const Territories = t.array(Territory);
const TerritoryGroups = t.array(TerritoryGroup);
const Publishers = t.array(Publisher);
const TerritoryAssignments = t.array(TerritoryAssignment);
const AddressAttempts = t.array(AddressAttempt);


// Create redux store
const persistConfig = {
    key: 'baruch-root',
    storage: storage,
    blacklist: [
        'ui',
        'router',
        'geolocation',
    ],
    transforms: [
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(Permissions.decode(outboundState), defaultReduxState.permissions),
            { whitelist: ['permissions'] },
        ),
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(Tags.decode(outboundState), defaultReduxState.tags),
            { whitelist: ['tags'] },
        ),
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(Territories.decode(outboundState), defaultReduxState.territories),
            { whitelist: ['territories'] },
        ),
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(TerritoryGroups.decode(outboundState), defaultReduxState.territory_groups),
            { whitelist: ['territory_groups'] },
        ),
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(Publishers.decode(outboundState), defaultReduxState.publishers),
            { whitelist: ['publishers'] },
        ),
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(Addresses.decode(outboundState), defaultReduxState.addresses),
            { whitelist: ['addresses'] },
        ),
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(TerritoryAssignments.decode(outboundState), defaultReduxState.assignments),
            { whitelist: ['assignments'] },
        ),
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(AddressAttempts.decode(outboundState), defaultReduxState.attempts),
            { whitelist: ['attempts'] },
        ),
        createTransform(
            (inboundState) => inboundState,
            (outboundState) => check(UserPrefs.decode(outboundState), defaultReduxState.prefs),
            { whitelist: ['prefs'] },
        ),
    ],
};
const persistedReducer = persistReducer(persistConfig, reducer);
export const store = createStore(persistedReducer, devToolsMiddleware);
export const persistor = persistStore(store);
