import * as Sentry from '@sentry/browser';
import {Classes} from '@blueprintjs/core';
import {Capacitor, Plugins, KeyboardStyle, Device} from '@capacitor/core';


// Initialize Sentry
if (process.env.SENTRY_DSN && window.location.host !== 'local.baruchapp.com') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        release: process.env.CI_COMMIT_SHA,
        environment: window.location.host,
    });
    // Set device info
    Device.getInfo().then((info) => {
        Sentry.setTag('device.appBuild', info.appBuild);
        Sentry.setTag('device.appVersion', info.appVersion);
        Sentry.setTag('device.isVirtual', info.isVirtual ? 'yes' : 'no');
        Sentry.setTag('device.manufacturer', info.manufacturer);
        Sentry.setTag('device.model', info.model);
        Sentry.setTag('device.operatingSystem', info.operatingSystem);
        Sentry.setTag('device.osVersion', info.osVersion);
        Sentry.setTag('device.platform', info.platform);
        Sentry.setExtra('device', info);
    });
}


// Load Styles
import './styles/main.scss';


// Set site theme (dark mode vs light mode)
if ('matchMedia' in window) {
    const updateThemeToMatchOS = () => {
        const osTheme = window.matchMedia("(prefers-color-scheme: dark)");
        // Configure the theme
        if (osTheme.matches) {
            document.body.classList.add(Classes.DARK);
        } else {
            document.body.classList.remove(Classes.DARK);
        }
        // Configure the Keyboard
        if (Capacitor.isPluginAvailable('Keyboard')) {
            const {Keyboard} = Plugins;
            Keyboard.setStyle({
                style: osTheme.matches ? KeyboardStyle.Dark : KeyboardStyle.Light,
            });
        }
    };
    updateThemeToMatchOS();
    window.matchMedia("(prefers-color-scheme: dark)").addListener(updateThemeToMatchOS);
}
