import React from 'react';
import {IServiceGroup} from '../../models.interfaces';
import {ISelectOption} from '../../forms.interfaces';
import {ServiceGroupSelector as Component} from './ServiceGroupSelector.component';


interface IProps {
    initialGroupID: number | null;
    options: IServiceGroup[];
    isOpen: boolean;
    onCancel: () => void;
    onSubmit: (group: IServiceGroup) => void;
}


interface IState {
    groupID: number | null;
}


export class ServiceGroupSelector extends React.PureComponent<IProps, IState> {

    public state: IState = {
        groupID: this.props.initialGroupID,
    };


    private readonly onSelect = (e: React.FormEvent<HTMLSelectElement>) => {
        this.setState({
            groupID: parseInt(e.currentTarget.value, 10),
        });
    }


    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const group = this.props.options.find((g) => {
            return g.id === this.state.groupID;
        });
        if (!group) {
            return;
        }
        this.props.onSubmit(group);
    }


    render () {
        const options = this.props.options.map((group) => {
            const opt: ISelectOption = {
                value: `${group.id}`,
                label: group.name,
            };
            return opt;
        });
        return (
            <Component
                options={options}
                isOpen={this.props.isOpen}
                groupID={this.state.groupID}
                onSelect={this.onSelect}
                onCancel={this.props.onCancel}
                onSubmit={this.onSubmit}
            />
        );
    }
}
