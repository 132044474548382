import React from 'react';
import classNames from 'classnames';
import {Classes} from "@blueprintjs/core";


interface IProps {
    inputID: string;
    label: string;
    helpText?: string;
    invalid?: boolean;
    errors?: string[];
    inline?: boolean;
    extraContentClasses?: string;
}


interface IState {
}


export class FormGroup extends React.PureComponent<IProps, IState> {
    private buildHelpText() {
        if (!this.props.helpText) {
            return null;
        }
        return (
            <div className={Classes.FORM_HELPER_TEXT}>
                {this.props.helpText}
            </div>
        );
    }


    private buildErrors () {
        if (!this.props.errors) {
            return null;
        }
        const errorClasses = classNames({
            [Classes.FORM_HELPER_TEXT]: true,
            [`${Classes.FORM_HELPER_TEXT}--error`]: true,
        });
        return this.props.errors.map((err, i) => {
            return (
                <div key={i} className={errorClasses}>{err}</div>
            );
        });
    }


    render () {
        const extraContentClasses = this.props.extraContentClasses || '';
        const formGroupClassNames = classNames({
            [Classes.FORM_GROUP]: true,
            [Classes.INTENT_DANGER]: this.props.invalid,
            [Classes.INLINE]: this.props.inline,
        });
        const contentClassNames = classNames({
            [Classes.FORM_CONTENT]: true,
            [extraContentClasses]: !!extraContentClasses,
        });
        return (
            <div className={formGroupClassNames}>
                <label className={Classes.LABEL} htmlFor={this.props.inputID}>
                    {this.props.label}
                </label>
                <div className={contentClassNames}>
                    {this.props.children}
                    {this.buildHelpText()}
                    {this.buildErrors()}
                </div>
            </div>
        );
    }
}
