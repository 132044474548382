import React from 'react';
import {Intent, Button, IButtonProps} from '@blueprintjs/core';


interface IProps {
    cancel?: IButtonProps;
    submit?: IButtonProps;
    isLoading?: boolean;
}


interface IState {
}


export class FormButtons extends React.PureComponent<IProps, IState> {

    render () {
        return (
            <div className="form-buttons">
                {this.props.cancel && (
                    <Button
                        intent={Intent.NONE}
                        type='button'
                        loading={this.props.isLoading}
                        {...this.props.cancel}
                    />
                )}
                {this.props.submit && (
                    <Button
                        intent={Intent.SUCCESS}
                        type='submit'
                        loading={this.props.isLoading}
                        {...this.props.submit}
                    />
                )}
            </div>
        );
    }
}
