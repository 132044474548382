/**
 * Class method decorator used to report timing of async behavior
 */
// tslint:disable-next-line no-any
export const recordTiming = (target: any, methodName: string, descriptor: PropertyDescriptor) => {
    const category = target.constructor.name;
    const origMethod = descriptor.value;
    descriptor.value = async function(...args: unknown[]) {
        const startTime = performance.now();
        // tslint:disable-next-line no-invalid-this
        const result = await origMethod.apply(this, args);
        const endTime = performance.now();
        const elapsed = Math.round(endTime - startTime);
        reportTiming(false, `${category}.${methodName}`, elapsed);
        return result;
    };
    return descriptor;
};


/**
 * Class method decorator used to report timing of sync behavior
 */
// tslint:disable-next-line no-any
export const recordSyncTiming = (target: any, methodName: string, descriptor: PropertyDescriptor) => {
    const category = target.constructor.name;
    const origMethod = descriptor.value;
    descriptor.value = function(...args: unknown[]) {
        const startTime = performance.now();
        // tslint:disable-next-line no-invalid-this
        const result = origMethod.apply(this, args);
        const endTime = performance.now();
        const elapsed = Math.round(endTime - startTime);
        reportTiming(true, `${category}.${methodName}`, elapsed);
        return result;
    };
    return descriptor;
};



const reportTiming = (sync: boolean, name: string, elapsed: number) => {
    const subname = sync ? 'sync' : 'async';
    console.log(`timer: ${name}: ${elapsed}`);
    window._paq.push(['trackEvent', 'Timers', name, subname, elapsed]);
};
