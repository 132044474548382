import React from 'react';
import * as Sentry from '@sentry/browser';
import {NonIdealState} from "@blueprintjs/core";
import {View} from "./wrappers/View";


interface IProps {
}

interface IState {
    hasError: boolean;
}


export class ErrorBoundary extends React.Component<IProps, IState> {
    state: IState = {
        hasError: false
    };


    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }


    componentDidCatch (error: Error) {
        Sentry.showReportDialog();
        Sentry.captureException(error);
    }


    render () {
        if (this.state.hasError) {
            const msg = (
                'An unexpected error occurred while trying to rendering this page. Please check your internet ' +
                'connection and try again later.'
            );
            return (
                <View id="error">
                    <NonIdealState icon='error'
                                   title='An Unexpected Error Occurred'
                                   description={msg} />
                </View>
            );
        }

        return this.props.children;
    }
}
