import React from 'react';
import {connect} from 'react-redux';
import {Route, RouteComponentProps} from 'react-router';
import {withRouter} from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import {EventData as SwipeEventData} from 'react-swipeable';
import {IURLParams, IReduxState} from '../reducers.interfaces';
import {sync} from '../sync';
import {getCurrentUser} from "../loaders";
import {setCurrentUser, setNavDrawerOpen} from "../dispatchers";
import {IUser} from "../models.interfaces";
import {wipeLocalData} from "../actions";
import {Loading} from "./Loading";
import {ErrorBoundary} from './ErrorBoundary';
import {Root as Component} from './Root.component';
import {VIEWS} from '../views';



interface IProps extends IReduxState {
}


interface IState {
    isLoading: boolean;
}


class RootContainer extends React.PureComponent<IProps & RouteComponentProps<IURLParams>, IState> {

    state: IState = {
        isLoading: true,
    };


    views = VIEWS.map((view) => {
        return {
            ...view,
            render: (props: RouteComponentProps<IURLParams>) => {
                const ViewComponent = view.Component;
                return (
                    <ErrorBoundary>
                        <ViewComponent route={props} />
                    </ErrorBoundary>
                );
            },
        };
    });


    private readonly onSwipedRight = (e: SwipeEventData) => {
        window._paq.push(['trackEvent', 'Root', 'onSwipedRight']);
        console.log('onSwipedRight', e);
        setNavDrawerOpen(true);
    }


    async componentDidMount () {
        // Update the current user
        let user: IUser | null = null;
        try {
            user = await getCurrentUser();
        } catch (e) {
            console.error(e);
        }
        const reduxUserID = this.props.permissions.user ? this.props.permissions.user.id : null;
        const actualUserID = user ? user.id : null;
        if (reduxUserID && (!actualUserID || reduxUserID !== actualUserID)) {
            wipeLocalData();
        }
        setCurrentUser(user);

        // If logged in, try to resume sync
        if (user) {
            try {
                if (this.props.permissions.user) {
                    await sync.resumeSync();
                } else {
                    await sync.initSync();
                }
            } catch (e) {
                console.error(e);
                Sentry.captureException(e);
            }
        }

        // Set user ID for analytics
        if (user && user.id && typeof(window._paq) !== "undefined") {
            window._paq.push(['setUserId', `${user.id}`]);
        }

        // Setup the Sentry context
        Sentry.configureScope((scope) => {
            scope.setUser({
                ...user,
                id: (user && user.id) ? `${user.id}` : undefined,
            });
        });

        // Update state to show that we're finished loading
        this.setState({
            isLoading: false,
        });
    }


    private getViews () {
        return this.views;
    }


    private buildContent () {
        // Loading View?
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        }
        // Build normal application views using react-router
        return this.getViews().map((view) => {
            return (
                <Route key={view.pattern}
                       strict={true}
                       exact={true}
                       path={view.pattern}
                       render={view.render} />
            );
        });
    }


    render () {
        return (
            <Component
                navDrawerOpen={this.props.ui.navDrawerOpen}
                onSwipedRight={this.onSwipedRight}
            >
                {this.buildContent()}
            </Component>
        );
    }
}


const mapStateToProps = (state: IReduxState): IProps => {
    return state;
};

export const BaruchApp = withRouter(connect(mapStateToProps)(RootContainer));
