import {Layout} from "./constants";
import {IReduxState} from "./reducers.interfaces";

export const defaultReduxState: IReduxState = {
    ui: {
        layout: Layout.PHONE,
        navDrawerOpen: false,
        isOnline: false,
        viewStack: () => { return []; },
        getNavBarTitle: () => { return 'Baruch'; },
        getNavBarContentLeft: () => { return null; },
        getNavBarContentRight: () => { return null; },
        filteredTerritoryGroupID: null,
    },
    geolocation: null,
    router: {
        location: {
            pathname: window.location.pathname,
            search: window.location.search,
            state: undefined,
            hash: window.location.hash,
        },
        action: 'PUSH',
    },
    permissions: {
        event_metadata: [],
        user: null,
        roles: [],
        service_groups: [],
        active_service_group: null,
    },
    tags: {
        address: [],
        result: [],
    },
    territories: [],
    territory_groups: [],
    publishers: [],
    addresses: {
        hasPrev: false,
        hasNext: true,
        pageNum: 0,
        totalItems: 0,
        items: [],
    },
    assignments: [],
    attempts: [],
    prefs: {
        distanceUnit: 'mi',
        workTerrAddrSort: 'address',
        mapApp: null,
    },
};
