import {AddressTags} from '../constants';
import {
    ITerritory,
    IPublisher,
    IAddress,
    IAddressSortOption,
    IAddressAttempt,
    ICoordinate,
    IDOMPosition,
} from '../models.interfaces';
import {calculateDistance} from './geo';


type IAddrComparator = (a: IAddress, b: IAddress) => number;
type IAddrComparators = { readonly [M in IAddressSortOption]: IAddrComparator };


export const getCollator = () => {
    return new Intl.Collator('en', {
        numeric: true,
        sensitivity: 'base',
        ignorePunctuation: true,
    });
};


export const sortTerritories = function(territories: ITerritory[]) {
    const collator = getCollator();
    return territories.sort((a, b) => collator.compare(a.name, b.name));
};


export const sortPublishers = function(publishers: IPublisher[]) {
    const collator = getCollator();
    return publishers.sort((a, b) => {
        if (a.last_name !== b.last_name) {
            return collator.compare(a.last_name, b.last_name);
        }
        if (a.first_name !== b.first_name) {
            return collator.compare(a.first_name, b.first_name);
        }
        return 0;
    });
};


export const sortAttempts = function(attempts: IAddressAttempt[], sortBy: 'asc' | 'desc') {
    return attempts.sort((a, b) => {
        const tsA = a.worked_date.getTime();
        const tsB = b.worked_date.getTime();
        if (tsA !== tsB) {
            if (sortBy === 'asc') {
                return tsA > tsB ? 1 : -1;
            }
            return tsA > tsB ? -1 : 1;
        }
        return 0;
    });
};


export const sortAddresses = function(_addresses: IAddress[], sortBy: IAddressSortOption, geolocation: IDOMPosition | null) {
    if (sortBy === 'proximity' && !geolocation) {
        sortBy = 'address';
    }
    const fromCoords: ICoordinate = geolocation && geolocation.coords
        ? [geolocation.coords.longitude, geolocation.coords.latitude]
        : [0, 0];

    const compareTags: IAddrComparator = function(a, b) {
        const nonSpeakerA = a.tags.includes(AddressTags.NON_SPEAKER);
        const nonSpeakerB = b.tags.includes(AddressTags.NON_SPEAKER);
        if (nonSpeakerA && !nonSpeakerB) {
            return 1;
        }
        if (!nonSpeakerA && nonSpeakerB) {
            return -1;
        }
        const duplicateA = a.tags.includes(AddressTags.DUPLICATE);
        const duplicateB = b.tags.includes(AddressTags.DUPLICATE);
        if (duplicateA && !duplicateB) {
            return 1;
        }
        if (!duplicateA && duplicateB) {
            return -1;
        }
        return 0;
    };

    const collator = getCollator();
    const addrComparators: IAddrComparators = {
        address: (a, b) => {
            const tagComparison = compareTags(a, b);
            if (tagComparison !== 0) {
                return tagComparison;
            }
            if (a.state.toLowerCase() !== b.state.toLowerCase()) {
                return collator.compare(a.state, b.state);
            }
            if (a.city.toLowerCase() !== b.city.toLowerCase()) {
                return collator.compare(a.city, b.city);
            }
            if (a.line1_street.toLowerCase() !== b.line1_street.toLowerCase()) {
                return collator.compare(a.line1_street, b.line1_street);
            }
            if (a.line1_number.toLowerCase() !== b.line1_number.toLowerCase()) {
                return collator.compare(a.line1_number, b.line1_number);
            }
            if (a.line2.toLowerCase() !== b.line2.toLowerCase()) {
                return collator.compare(a.line2, b.line2);
            }
            if (a.name.toLowerCase() !== b.name.toLowerCase()) {
                return collator.compare(a.name, b.name);
            }
            return 0;
        },

        proximity: (a, b) => {
            const tagComparison = compareTags(a, b);
            if (tagComparison !== 0) {
                return tagComparison;
            }
            if (a.longitude && a.latitude && b.longitude && b.latitude) {
                const distA = calculateDistance(fromCoords, [a.longitude, a.latitude]);
                const distB = calculateDistance(fromCoords, [b.longitude, b.latitude]);
                if (distA !== distB) {
                    return distA > distB ? 1 : -1;
                }
            }
            return addrComparators.address(a, b);
        },
    };
    const comparator = addrComparators[sortBy];
    return _addresses
        .slice()
        .sort(comparator)
        .map((addr, i) => {
            addr.idx = (i + 1);
            return addr;
        });
};
