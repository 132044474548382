import {Classes, Portal, H4, H6, MenuItem, MenuDivider, Intent, IconName} from "@blueprintjs/core";
import classNames from "classnames";
import {Location} from "history";
import React from "react";
import {IServiceGroup, IUser} from "../models.interfaces";
import {ILink, ILinkGroup} from "../navigation";
import {ServiceGroupSelector} from "./molecules/ServiceGroupSelector.container";
import {UserPrefsDialog} from "./molecules/UserPrefsDialog.container";


interface IProps {
    activeServiceGroupID: number | null;
    currentLocation: Location | null;
    user: IUser | null;
    serviceGroups: IServiceGroup[];
    links: ILinkGroup[];
    navDrawerOpen: boolean;
    groupSelectorOpen: boolean;
    prefsDialogOpen: boolean;
    helpMenuLinks: Array<{
        icon: IconName;
        label: string;
        link: string;
    }>;
    onTitleClick: () => void;
    onOpenGroupSelector: () => void;
    onCloseGroupSelector: () => void;
    onOpenPrefsDialog: () => void;
    onClosePrefsDialog: () => void;
    onNavigate: (url: string) => void;
    onCloseNavDrawer: () => void;
    onLogout: () => void;
    setActiveServiceGroup: (group: IServiceGroup) => void;
    onSubmitFeedback: () => void;
}


interface IState {
}


export class GlobalNavDrawer extends React.PureComponent<IProps, IState> {

    private buildMenuItem (currentBasePath: string, link: ILink) {
        const self = this;
        const onNavClick = function() {
            self.props.onNavigate(link.url);
        };
        const isActive = (link.url.startsWith(currentBasePath) && (currentBasePath !== '/'))
                         ||
                         (link.url === currentBasePath);
        return (
            <MenuItem key={link.url}
                      active={isActive}
                      icon={link.icon}
                      text={link.label}
                      onClick={onNavClick} />
        );
    }


    private buildGroup (currentBasePath: string, linkGroup: ILinkGroup) {
        const self = this;
        return (
            <React.Fragment key={`group-${linkGroup.label}`}>
                <li key={`heading-${linkGroup.label}`} className={Classes.MENU_HEADER}>
                    <H6>{linkGroup.label}</H6>
                </li>
                {linkGroup.links.map((link) => {
                    return self.buildMenuItem(currentBasePath, link);
                })}
            </React.Fragment>
        );
    }


    private buildServiceGroupHeading () {
        const self = this;
        // Find the currently active service group
        const activeGroup = this.props.serviceGroups.find((group) => {
            return group.id === self.props.activeServiceGroupID;
        });

        // Abort if there's no active service group
        if (!activeGroup) {
            return null;
        }

        return (
            <li className={Classes.MENU_HEADER}>
                <H4>{activeGroup.name}</H4>
            </li>
        );
    }


    private buildNavMenu () {
        if (!this.props.user) {
            return null;
        }

        const self = this;

        // Get the current base path to determine which nav bar entry to mark as active
        const currentBasePath = self.props.currentLocation
            ? self.props.currentLocation.pathname.replace(/^(\/[^\/]+)\/.*$/, "$1")
            : "/";

        // Render list of links
        const links = this.props.links.map((linkGroup) => {
            return self.buildGroup(currentBasePath, linkGroup);
        });

        return (
            <ul className={`${Classes.MENU} global-nav-drawer__nav-menu`}>
                {this.buildServiceGroupHeading()}
                {links}
            </ul>
        );
    }


    private buildHelpMenu () {
        const items = this.props.helpMenuLinks.map((link) => {
            if (!link.link) {
                return null;
            }
            return (
                <MenuItem key={link.link}
                          icon={link.icon}
                          text={link.label}
                          target="_blank"
                          href={link.link} />
            );
        });
        return (
            <ul className={`${Classes.MENU} global-nav-drawer__help-menu`}>
                <li className={Classes.MENU_HEADER}>
                    <H6>Help</H6>
                </li>
                <MenuItem icon='issue-new'
                          text='Submit Feedback'
                          onClick={this.props.onSubmitFeedback} />
                {items}
            </ul>
        );
    }


    private buildUserMenu () {
        // Abort if there's no user
        if (!this.props.user) {
            return null;
        }

        // Figure out how to address the user
        const greeting = this.props.user.first_name || this.props.user.username;

        // Render menu items
        return (
            <ul className={`${Classes.MENU} global-nav-drawer__user-menu`}>
                <li className={Classes.MENU_HEADER}>
                    <H6>Welcome, {greeting}</H6>
                </li>
                <MenuItem
                    icon="cog"
                    text="Preferences"
                    onClick={this.props.onOpenPrefsDialog}
                />
                <MenuItem
                    icon="cog"
                    text="Service Group"
                    onClick={this.props.onOpenGroupSelector}
                />
                <MenuDivider />
                <MenuItem
                    icon="log-out"
                    text="Logout"
                    intent={Intent.DANGER}
                    onClick={this.props.onLogout}
                />
            </ul>
        );
    }


    render () {
        const drawerClasses = classNames({
            'global-nav-drawer': true,
            'global-nav-drawer--open': this.props.navDrawerOpen,
        });
        const overlayClasses = classNames({
            [Classes.OVERLAY_BACKDROP]: true,
            'global-nav-drawer-overlay': true,
        });
        let overlay: JSX.Element | null = null;
        if (this.props.navDrawerOpen) {
            overlay = (
                <div className={overlayClasses} onClick={this.props.onCloseNavDrawer}>
                </div>
            );
        }
        return (
            <Portal>
                {overlay}
                <div className={drawerClasses}>
                    {this.buildNavMenu()}
                    {this.buildHelpMenu()}
                    {this.buildUserMenu()}
                </div>
                <ServiceGroupSelector
                    initialGroupID={this.props.activeServiceGroupID}
                    options={this.props.serviceGroups}
                    isOpen={this.props.groupSelectorOpen}
                    onCancel={this.props.onCloseGroupSelector}
                    onSubmit={this.props.setActiveServiceGroup}
                />
                <UserPrefsDialog
                    isOpen={this.props.prefsDialogOpen}
                    onClose={this.props.onClosePrefsDialog}
                />
            </Portal>
        );
    }
}
