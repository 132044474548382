import * as t from 'io-ts';
import {isLeft, Either} from 'fp-ts/lib/Either';
import {date} from 'io-ts-types/lib/date';
import {DateFromISOString} from 'io-ts-types/lib/DateFromISOString';
import {reporter} from 'io-ts-reporters';
import {RoleName, AddressTags, ResultTags} from './constants';
import {Field} from './drf.codecs';


export const check = <T>(result: Either<t.Errors, T>, _default?: T) => {
    if (isLeft(result)) {
        const errs = reporter(result);
        if (_default !== undefined) {
            errs.forEach((err) => {
                console.warn(err);
            });
            return _default;
        }
        errs.forEach((err) => {
            console.error(err);
        });
        throw new Error(errs[0]);
    }
    return result.right;
};


export const nullable = <RT extends t.Mixed>(type: RT) => {
    return t.union([t.null, type]);
};


export const optional = <RT extends t.Mixed>(type: RT) => {
    return t.union([t.undefined, type]);
};


// See https://github.com/gcanti/io-ts/issues/216
export class EnumType<A> extends t.Type<A> {
    public readonly _tag: 'EnumType' = 'EnumType';
    public enumObject!: object;
    constructor (e: object, name?: string) {
        super(
            (name || 'enum'),
            (u): u is A => {
                return Object.values(this.enumObject)
                    .some((v) => v === u);
            },
            (u, c) => {
                return this.is(u)
                    ? t.success(u)
                    : t.failure(u, c);
            },
            t.identity,
        );
        this.enumObject = e;
    }
}
export const createEnumType = <T>(e: object, name?: string) => new EnumType<T>(e, name);


// Date codec which supports parsing a date from an ISO string, but will also
// just pass through an object if it's a date already
export const JSONDate = t.union([date, DateFromISOString]);


export const ReduxEventMeta = t.interface({
    type: t.string,
    is_allowed: t.boolean,
    meta_schema: t.record(t.string, Field),
    payload_schema: t.record(t.string, Field),
});
export const ReduxEventMetaArray = t.array(ReduxEventMeta);


export const PagedAPIResponse = <RT extends t.Mixed>(type: RT) => {
    return t.interface({
        count: t.number,
        page_size: t.number,
        next: nullable(t.string),
        previous: nullable(t.string),
        results: t.array(type),
    });
};


export const PagedLoaderResponse = <RT extends t.Mixed>(type: RT) => {
    const base = PagedAPIResponse(type);
    const extra = t.interface({
        pageNum: t.number,
    });
    return t.intersection([base, extra]);
};


export const Tag = <T extends t.Mixed>(tagEnum: T) => {
    return t.interface({
        id: t.number,
        abbreviation: tagEnum,
        name: t.string,
    });
};


export const User = t.interface({
    id: nullable(t.number),
    username: t.string,
    email: t.string,
    first_name: t.string,
    last_name: t.string,
    is_active: t.boolean,
    service_groups: t.array(t.number),
});


export const Publisher = t.interface({
    id: nullable(t.number),
    username: t.string,
    email: t.string,
    first_name: t.string,
    last_name: t.string,
    is_active: t.boolean,
});


export const LoginResponse = t.intersection([User, t.interface({
    auth_token: t.string,
    auth_token_expires: JSONDate,
})]);


export const ServiceGroup = t.interface({
    id: t.number,
    name: t.string,
});


export const UserRole = t.interface({
    service_group: t.number,
    role_type: t.interface({
        id: t.number,
        name: createEnumType<RoleName>(RoleName, 'RoleName'),
    }),
});


export const DOMCoordinates = t.interface({
    accuracy: t.number,
    latitude: t.number,
    longitude: t.number,
    altitude: nullable(t.number),
    altitudeAccuracy: nullable(t.number),
    heading: nullable(t.number),
    speed: nullable(t.number),
});
export const DOMPosition = t.interface({
    timestamp: t.number,
    coords: DOMCoordinates,
});


export const Coordinate = t.tuple([t.number, t.number]);
export const CoordinatePath = t.array(Coordinate);
export const Polygon = t.array(CoordinatePath);


export const GeoPoint = t.interface({
    type: t.literal('Point'),
    coordinates: Coordinate,
});


export const GeoPolygon = t.interface({
    type: t.literal("Polygon"),
    coordinates: Polygon,
});


export const AddressTag = Tag(createEnumType<AddressTags>(AddressTags, 'AddressTags'));
export const ResultTag = Tag(createEnumType<ResultTags>(ResultTags, 'ResultTags'));


export const Territory = t.interface({
    id: nullable(t.number),
    service_group: t.number,
    name: t.string,
    description: t.string,
    groups: t.array(t.number),
    num_addresses: t.number,
    boundaries: nullable(GeoPolygon),
});
export const TerritoryAPIResponse = t.array(Territory);


export const TerritoryGroup = t.interface({
    id: nullable(t.number),
    service_group: t.number,
    name: t.string,
    territories: t.array(t.number),
});


export const Address = t.intersection([
    t.interface({
        id: nullable(t.number),
        service_group: t.number,
        territory: nullable(t.number),
        line1_number: t.string,
        line1_street: t.string,
        line2: t.string,
        city: t.string,
        state: t.string,
        zipcode: t.string,
        coordinates: nullable(GeoPoint),
        longitude: nullable(t.number),
        latitude: nullable(t.number),
        name: t.string,
        phone_number: nullable(t.string),
        notes: t.string,
        tags: t.array(createEnumType<AddressTags>(AddressTags, 'AddressTags')),
    }),
    t.partial({
        idx: t.number,
        last_worked_date: nullable(JSONDate),
    }),
]);
export const AddressNotes = t.interface({
    id: nullable(t.number),
    notes: t.string,
    tags: t.array(createEnumType<AddressTags>(AddressTags, 'AddressTags')),
});
export const Addresses = t.array(Address);
export const AddressAPIResponse = PagedAPIResponse(Address);
export const AddressLoaderResponse = PagedLoaderResponse(Address);


/**
 * An AddressGroup models a logical group of addresses that are all at the same
 * physical location. For example, an apartment building is a single address group
 * which contains many Address records (each individual apartment).
 */
export const AddressGroup = t.interface({
    // Metadata about the group
    id: t.number,
    line1_number: t.string,
    line1_street: t.string,
    city: t.string,
    state: t.string,
    zipcode: t.string,
    coordinates: nullable(GeoPoint),
    // Contents
    addresses: t.array(Address),
});


export const TerritoryVersion = t.interface({
    id: nullable(t.number),
    territory: t.number,
    version_number: t.number,
    name: t.string,
    description: t.string,
    num_addresses: t.number,
    boundaries: nullable(GeoPolygon),
});


export const TerritoryAssignment = t.interface({
    id: nullable(t.number),
    owner: t.number,
    shared_with: t.array(t.number),
    territory_version: t.number,
    territory: t.number,
    date_out: JSONDate,
    date_in: nullable(JSONDate),
    tags: nullable(t.array(t.string)),
});


export const AddressAttempt = t.intersection([
    t.interface({
        id: nullable(t.number),
        assignment: t.number,
        address: t.number,
        worked_date: JSONDate,
        notes: t.string,
        tags: t.array(createEnumType<ResultTags>(ResultTags, 'ResultTags')),
    }),
    t.partial({
        owner: t.number,
        author: nullable(t.number),
    }),
]);
export const AddressAttemptAPIResponse = t.array(AddressAttempt);


export const RecordAttemptData = t.interface({
    date: JSONDate,
    addressNotes: t.string,
    addressTags: t.array(createEnumType<AddressTags>(AddressTags, 'AddressTags')),
    resultNotes: t.string,
    resultTags: t.array(createEnumType<ResultTags>(ResultTags, 'ResultTags')),
});


export const RolesAPIResponse = t.record(
    createEnumType<RoleName>(RoleName, 'RoleName'),
    t.boolean);


export const AddressSortOption = t.keyof({
    'address': null,
    'proximity': null,
});

export const DistanceUnit = t.keyof({
    'km': null,
    'mi': null,
});

export const MapApp = t.keyof({
    'apple': null,
    'google-app': null,
    'google-web': null,
});

export const UserPrefs = t.interface({
    distanceUnit: DistanceUnit,
    workTerrAddrSort: AddressSortOption,
    mapApp: nullable(MapApp),
});


export const CMSPageAPIResponse = <PT extends t.Mixed>(type: PT) => {
    return t.interface({
        meta: t.interface({
            total_count: t.number,
        }),
        items: t.array(type),
    });
};


export const CMSBlogPost = t.interface({
    id: t.number,
    meta: t.interface({
        type: t.string,
        detail_url: t.string,
        html_url: t.string,
        slug: t.string,
        show_in_menus: t.boolean,
        seo_title: t.string,
        search_description: t.string,
        first_published_at: t.string,
    }),
    title: t.string,
    posted_datetime: JSONDate,
    intro: t.string,
    body: t.string,
});
export const CMSBlogPostAPIResponse = CMSPageAPIResponse(CMSBlogPost);

export const ServiceGroupStatistic = t.tuple([
    t.string,
    t.number,
]);
export const ServiceGroupStatistics = t.array(ServiceGroupStatistic);


export const ServiceGroupAdvancedStatistic = t.interface({
    title: t.string,
    description: t.string,
    stacked: t.boolean,
    unit: nullable(t.string),
    colors: t.union([
        t.literal('qualitative'),
        t.literal('sequential'),
    ]),
    data: t.array(
        t.interface({
            label: t.string,
            points: t.array(
                t.interface({
                    label: t.string,
                    value: t.number,
                }),
            ),
        }),
    ),
});
export const ServiceGroupAdvancedStatistics = t.array(ServiceGroupAdvancedStatistic);


export const LocationGuessResp = t.partial({
    as: t.string,
    city: t.string,
    country: t.string,
    countryCode: t.string,
    isp: t.string,
    lat: t.number,
    lon: t.number,
    org: t.string,
    query: t.string,
    region: t.string,
    regionName: t.string,
    status: t.string,
    timezone: t.string,
    zip: t.string,
});


export const AddressHeatmapPoint = t.tuple([
    t.number,
    t.number,
]);
export const AddressHeatmapPoints = t.array(AddressHeatmapPoint);
