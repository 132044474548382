import * as Sentry from '@sentry/browser';
import {connectRouter} from 'connected-react-router';
import {combineReducers, AnyAction} from "redux";
import {v4 as uuidv4} from 'uuid';
import {Plugins} from '@capacitor/core';
import {Action} from './constants';
import {history} from './history';
// import {ISyncedAction} from "./actions.interfaces";
import {isSyncedAction} from "./actions.codecs";
import {IReduxState, IReduxStateHistoryEntry} from "./reducers.interfaces";
import {defaultReduxState} from "./defaults";
import reducerUI from "./reducers/ui";
import reducerAddresses from "./reducers/addresses";
import reducerAttempts from "./reducers/attempts";
import reducerGeoLocation from "./reducers/geolocation";
import reducerPermissions from "./reducers/permissions";
import reducerPublishers from "./reducers/publishers";
import reducerTags from "./reducers/tags";
import reducerTerritories from "./reducers/territories";
import reducerAssignments from "./reducers/territoryassignments";
import reducerTerritoryGroups from "./reducers/territorygroups";
import reducerPrefs from "./reducers/prefs";

const {Storage} = Plugins;


let stateHistory: IReduxStateHistoryEntry<AnyAction>[] = [];


export const getLatestEventID = () => {
    return (stateHistory.length > 0)
        ? stateHistory[stateHistory.length - 1].eventID
        : null;
};


const _reducer = combineReducers({
    ui: reducerUI,
    geolocation: reducerGeoLocation,
    router: connectRouter(history),
    permissions: reducerPermissions,
    addresses: reducerAddresses,
    territories: reducerTerritories,
    territory_groups: reducerTerritoryGroups,
    publishers: reducerPublishers,
    assignments: reducerAssignments,
    tags: reducerTags,
    attempts: reducerAttempts,
    prefs: reducerPrefs,
});


export const getStateHistorySnapshot = (): typeof stateHistory => {
    return JSON.parse(JSON.stringify(stateHistory));
};


const historyRecordingReducer: typeof _reducer = (oldState, action) => {
    const eventID = (isSyncedAction(action) && action.meta && action.meta.event_id)
        ? action.meta.event_id
        : uuidv4();
    const newState = _reducer(oldState, action);
    stateHistory.push({
        eventID: eventID,
        event: action,
        postEventState: newState,
    });
    return newState;
};


const handleResetState = (oldState: IReduxState) => {
    stateHistory = [];
    Storage.clear();
    return {
        ...defaultReduxState,
        ui: {
            ...defaultReduxState.ui,
            layout: oldState.ui.layout,
            navDrawerOpen: oldState.ui.navDrawerOpen,
        },
    };
};


const reducer: typeof _reducer = (oldState = defaultReduxState, action) => {
    // Log action as a Sentry breadcrumb
    const breadcrumbEventID = action && action.meta && action.meta.event_id ? action.meta.event_id : null;
    Sentry.addBreadcrumb({
        category: 'redux.run-reducer',
        level: Sentry.Severity.Info,
        message: `Processing Redux action: ${action.type}[${breadcrumbEventID}]`,
        data: {
            action: action,
        },
    });

    // Handle Root level actions
    if (action.type === Action.RESET_STORE) {
        return handleResetState(oldState);
    }

    return historyRecordingReducer(oldState, action);

 //    // For an event without an event ID, just run the normal reducers
 //    if (!isSyncedAction(action) || !action.meta || !action.meta.event_id) {
 //        return historyRecordingReducer(oldState, action);
 //    }

 //    // For an event with an event ID:
 //    // - Roll the state back to the state according to the event's parent ID
 //    // - Run the event
 //    // - Replay an additional memory-only events on-top of the new state
 //    const eventID = action.meta.event_id;
 //    const parentID = action.meta.parent_event_id;
 //    const parentHistoryEntry = stateHistory.find((entry) => {
 //        return entry.eventID === parentID;
 //    });

 //    // If no history entry was found, just handle this like a normal reducer
 //    if (!parentHistoryEntry) {
 //        return historyRecordingReducer(oldState, action);
 //    }

 //    // Get the redux state after applying the action's parent action
 //    const baseState = parentHistoryEntry.postEventState;

 //    // Get all the other actions that were previously played on-top of the base state
 //    const parentHistoryEntryIndex = stateHistory.indexOf(parentHistoryEntry);
 //    const replayEntries = stateHistory
 //        .slice(parentHistoryEntryIndex + 1)
 //        .filter((entry) => {
 //            return entry.eventID !== eventID;
 //        });

 //    // Apply the new action on-top of the baseState
 //    let newState = _reducer(baseState, action);

 //    // Replay other history entries on-top of the new state
 //    newState = replayEntries
 //        .reduce((memo, entry) => {
 //            return _reducer(memo, entry.event);
 //        }, newState);

 //    // Reconstruct the history now that the event rebase has been completed.
 //    const historyEntry: IReduxStateHistoryEntry<ISyncedAction> = {
 //        eventID: action.meta.event_id,
 //        event: action,
 //        postEventState: newState,
 //    };
 //    stateHistory = stateHistory
 //        .slice(0, parentHistoryEntryIndex + 1)
 //        .concat([historyEntry])
 //        .concat(replayEntries);
 //    console.log(`Rebased ${action.type}[${eventID}] on-top of ${parentHistoryEntry.event.type}[${parentID}] and then
 // replayed ${replayEntries.length} actions.`);

 //    // Finally, return the new state
 //    return newState;
};


export default reducer;
