import {push} from "connected-react-router";
import {Plugins} from '@capacitor/core';
import {
    IActionCheckoutTerritory,
    IActionAutoAssignAddresses,
    IActionDeleteAddress,
    IActionDeletePublisher,
    IActionReactivatePublisher,
    IActionDeleteTerritory,
    IActionDeleteTerritoryGroup,
    IActionSetActiveGroup,
    IActionSetPublisherPassword,
    IActionSetPublisherRoles,
    IActionUpdateAddress,
    IActionUpdateAddressNotes,
    IActionGeocodeAddress,
    IActionUpdateAssignment,
    IActionDeleteAssignment,
    IActionUpdatePublisher,
    IActionUpdateTerritory,
    IActionUpdateTerritoryGroup,
    IAddressAttemptActions,
} from "./actions.interfaces";
import {Action} from "./constants";
import {
    IAddress,
    IAddressNotes,
    IAddressAttempt,
    IPublisher,
    ITerritory,
    ITerritoryAssignment,
    ITerritoryGroup,
    IRolesAPIResponse,
} from "./models.interfaces";
import {check, LoginResponse} from './models';
import {store, persistor} from "./store";
import {sync} from "./sync";
import {getAuthToken, setAuthToken} from "./utils/auth";
import {getAPIClient} from "./utils/ajax";
import {makeUUID} from "./utils/factories";

const {Storage} = Plugins;


export const wipeLocalData = async () => {
    await persistor.purge();
    await Storage.clear();
};



export const login = async (username: string, password: string) => {
    const api = await getAPIClient();
    const resp = await api
        .post(`/api/login/`, true)
        .send({
            username,
            password,
        });
    const data = check(LoginResponse.decode(resp.body));
    await updateAuthToken(data.auth_token);
};



export const updateAuthToken = async (token: string) => {
    await wipeLocalData();
    await setAuthToken(token);
    window.location.href = "/";
};



export const logout = async () => {
    window._paq.push(['resetUserId']);
    const api = await getAPIClient();
    try {
        await api.delete(`/api/login/`);
    } catch (e) {
        console.log(e);
    }
    await wipeLocalData();
    window.location.href = "/";
};



export const navigateTo = function(url: string) {
    window._paq.push(['trackLink', url, 'link']);
    window._paq.push(['setReferrerUrl', window.location.href]);
    window._paq.push(['setCustomUrl', url]);
    window._paq.push(['trackPageView']);
    store.dispatch(push(url));
};



export const setActiveServiceGroup = async (activeServiceGroupID: number | null, groupID: number) => {
    const event: IActionSetActiveGroup = {
        type: Action.SET_ACTIVE_GROUP,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: groupID,
        },
    };
    // Fetch the current auth token
    const token = await getAuthToken();
    // Set the new active group
    await sync.sendServerEvent(event);
    // Wipe all local caches
    await wipeLocalData();
    // Reset the auth token
    if (token) {
        await setAuthToken(token);
    }
    // Reload the app
    setTimeout(() => {
        window.location.href = "/";
    }, 0);
};



export const updateTerritory = function(activeServiceGroupID: number | null, territory: ITerritory) {
    // The APi requires this to be a proper closed-polygon. Therefore, make
    // sure the last coordinate is always the same as the first coordinate
    // before sending the event.
    territory.boundaries = territory.boundaries || {
        type: 'Polygon',
        coordinates: [],
    };
    const coordinates = territory.boundaries.coordinates.map((coords) => {
        const firstLng = coords[0][0];
        const firstLat = coords[0][1];
        const lastLng = coords[coords.length - 1][0];
        const lastLat = coords[coords.length - 1][1];
        if (firstLat !== lastLat || firstLng !== lastLng) {
            coords = coords.concat([coords[0]]);
        }
        return coords;
    });
    territory.boundaries.coordinates = coordinates;

    const event: IActionUpdateTerritory = {
        type: Action.UPDATE_TERRITORY,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: territory,
    };
    return sync.sendServerEvent(event);
};



export const deleteTerritory = function(activeServiceGroupID: number | null, territoryID: number) {
    const event: IActionDeleteTerritory = {
        type: Action.DELETE_TERRITORY,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: territoryID,
        },
    };
    return sync.sendServerEvent(event);
};



export const checkoutTerritory = function(
    activeServiceGroupID: number | null,
    territory: ITerritory,
    publisher: IPublisher,
    dateOut: Date,
) {
    if (!territory.id) {
        throw new Error('Territory ID can not be null');
    }
    if (!publisher.id) {
        throw new Error('Publisher ID can not be null');
    }
    const event: IActionCheckoutTerritory = {
        type: Action.CHECKOUT_TERRITORY,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            territory: territory.id,
            publisher: publisher.id,
            date_out: dateOut,
        },
    };
    return sync.sendServerEvent(event);
};



export const deleteTerritoryGroup = function(activeServiceGroupID: number | null, groupID: number) {
    const event: IActionDeleteTerritoryGroup = {
        type: Action.DELETE_TERRITORY_GROUP,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: groupID,
        },
    };
    return sync.sendServerEvent(event);
};



export const updateTerritoryGroup = function(activeServiceGroupID: number | null, group: ITerritoryGroup) {
    const event: IActionUpdateTerritoryGroup = {
        type: Action.UPDATE_TERRITORY_GROUP,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: group,
    };
    return sync.sendServerEvent(event);
};



export const deletePublisher = function(activeServiceGroupID: number | null, publisherID: number) {
    const event: IActionDeletePublisher = {
        type: Action.DELETE_PUBLISHER,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: publisherID,
        },
    };
    return sync.sendServerEvent(event);
};



export const reactivatePublisher = function(activeServiceGroupID: number | null, publisherID: number) {
    const event: IActionReactivatePublisher = {
        type: Action.REACTIVATE_PUBLISHER,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: publisherID,
        },
    };
    return sync.sendServerEvent(event);
};



export const updatePublisher = function(activeServiceGroupID: number | null, publisher: IPublisher) {
    if (!publisher.username) {
        publisher.username = makeUUID();
    }
    const event: IActionUpdatePublisher = {
        type: Action.UPDATE_PUBLISHER,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: publisher,
    };
    return sync.sendServerEvent(event);
};



export const setPublisherPassword = function(activeServiceGroupID: number | null, publisher: IPublisher, newPassword: string) {
    if (!publisher.id) {
        throw new Error('Publisher ID can not be null');
    }
    const event: IActionSetPublisherPassword = {
        type: Action.SET_PUBLISHER_PASSWORD,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: publisher.id,
            password: newPassword,
        },
    };
    return sync.sendServerEvent(event);
};


export const updatePublisherRoles = async function(serviceGroupID: number, publisher: IPublisher, roles: IRolesAPIResponse) {
    if (!publisher.id) {
        throw new Error('Publisher ID can not be null');
    }
    const event: IActionSetPublisherRoles = {
        type: Action.SET_PUBLISHER_ROLES,
        meta: {
            service_group: serviceGroupID,
        },
        payload: {
            service_group: serviceGroupID,
            user: publisher.id,
            roles: roles,
        },
    };
    return sync.sendServerEvent(event);
};


export const updateAssignment = function(activeServiceGroupID: number | null, assignment: ITerritoryAssignment) {
    const event: IActionUpdateAssignment = {
        type: Action.UPDATE_ASSIGNMENT,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: assignment,
    };
    return sync.sendServerEvent(event);
};



export const deleteAssignment = function(activeServiceGroupID: number | null, assignmentID: number) {
    const event: IActionDeleteAssignment = {
        type: Action.DELETE_ASSIGNMENT,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: assignmentID,
        },
    };
    return sync.sendServerEvent(event);
};



export const autoassignAddresses = function(activeServiceGroupID: number | null) {
    const event: IActionAutoAssignAddresses = {
        type: Action.AUTOASSIGN_ADDRESSES,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: null,
    };
    return sync.sendServerEvent(event);
};



export const updateAddress = function(activeServiceGroupID: number | null, address: IAddress) {
    const event: IActionUpdateAddress = {
        type: Action.UPDATE_ADDRESS,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: address,
    };
    return sync.sendServerEvent(event);
};



export const updateAddressNotes = function(activeServiceGroupID: number | null, notes: IAddressNotes, background = false, enableErrorToast = true) {
    const event: IActionUpdateAddressNotes = {
        type: Action.UPDATE_ADDRESS_NOTES,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: notes,
    };
    return sync.sendServerEvent(event, background, enableErrorToast);
};



export const geocodeAddress = function(activeServiceGroupID: number | null, address: IAddress) {
    if (!address.id) {
        throw new Error('Address ID can not be null');
    }
    const event: IActionGeocodeAddress = {
        type: Action.GEOCODE_ADDRESS,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: address.id,
        },
    };
    return sync.sendServerEvent(event);
};



export const deleteAddress = function(activeServiceGroupID: number | null, addressID: number) {
    const event: IActionDeleteAddress = {
        type: Action.DELETE_ADDRESS,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: {
            id: addressID,
        },
    };
    return sync.sendServerEvent(event);
};



export const recordAddressAttempt = function(activeServiceGroupID: number | null, attempt: IAddressAttempt, background = false) {
    const event: IAddressAttemptActions = {
        type: Action.RECORD_ADDRESS_ATTEMPT,
        meta: {
            service_group: activeServiceGroupID,
        },
        payload: attempt,
    };
    return sync.sendServerEvent(event, background);
};
