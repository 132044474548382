import React from 'react';
import {parse as parseURL} from 'url';
import {ConnectedRouter} from 'connected-react-router';
import {PersistGate} from 'redux-persist/integration/react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {Capacitor, Plugins, GeolocationPosition} from '@capacitor/core';
import {store, persistor} from './store';
import {history} from './history';
import {setLayout} from './dispatchers';
import {Layout, LAYOUTS, Action} from './constants';
import {IActionSetGeoLocation} from './actions.interfaces';
import {navigateTo} from './actions';
import {BaruchApp} from './views/Root.container';
import {MatomoEventQueue} from './matomo.interfaces';


// Amend the Window Interface
declare global {
    interface Window {
        // Globlal callback for loading the GMaps API
        _GoogleMapsApi?: {
            onMapLoaded: () => void;
        };
        // Matomo tracking API
        _paq: MatomoEventQueue;
    }
}


// Check that service workers are registered
if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js');
    });
}


// Load Styles and basic site components
import './_site';


// Hide the capacitor splash screen
if (Capacitor.isPluginAvailable('SplashScreen')) {
    const {SplashScreen} = Plugins;
    SplashScreen.hide();
}


// Configure the Keyboard
if (Capacitor.isPluginAvailable('Keyboard')) {
    const {Keyboard} = Plugins;
    Keyboard.setAccessoryBarVisible({
        isVisible: true,
    });
}


// Start trying to locate the user's geolocation
if (Capacitor.isPluginAvailable('Geolocation')) {
    const {Geolocation} = Plugins;
    const updatePosition = (position: GeolocationPosition | Position) => {
        store.dispatch<IActionSetGeoLocation>({
            type: Action.SET_GEOLOCATION,
            payload: (position as Position),
        });
    };
    const gpsOptions = {
        enableHighAccuracy: true,
        maximumAge: 300_000,
        timeout: 5_000,
        requireAltitude: false,
    };
    Geolocation.getCurrentPosition(gpsOptions).then(updatePosition);
    Geolocation.watchPosition(gpsOptions, (position, err) => {
        if (err) {
            console.log(err);
        } else {
            updatePosition(position);
        }
    });
}


// Set the current layout
const updateLayout = function() {
    const windowWidth = window.innerWidth;
    for (const layout of LAYOUTS) {
        if (windowWidth >= layout) {
            setLayout(layout);
            return;
        }
    }
    setLayout(Layout.PHONE);
};
updateLayout();
window.addEventListener('resize', updateLayout);


// Register app event handlers with the OS
if (Capacitor.isPluginAvailable('App')) {
    const {App} = Plugins;
    App.addListener('appUrlOpen', (data) => {
        try {
            console.log(`appUrlOpen: ${data.url}`);
            const urlParts = parseURL(data.url, true, true);
            if (urlParts.pathname) {
                console.log(`navigateTo: ${urlParts.pathname}`);
                navigateTo(urlParts.pathname);
            }
        } catch (e) {
            console.error(e);
        }
    });
}


// Start application
const startApp = function() {
    const elem = document.querySelector('#baruch-app');
    if (!elem) {
        return;
    }
    const component = (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <BaruchApp />
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    );
    render(component, elem);
};


startApp();
