import {IAddressSortOption, IDistanceUnit, IMapApp} from './models.interfaces';
import {ITypedSelectOption} from './forms.interfaces';


export enum AlertType {
    ONLINE,
    OFFLINE,
    UNAUTHENTICATED,
}


/**
 * UI Breakpoints
 */
export enum Layout {
    PHONE = 0,
    TABLET = 750,
    DESKTOP = 1000,
}
export const LAYOUTS: Layout[] = [Layout.DESKTOP, Layout.TABLET, Layout.PHONE];


/**
 * User Roles
 */
export enum RoleName {
    GROUP_ADMIN = "Group Admin",
    PUBLISHER = "Publisher",
}



/**
 * Redux actions
 */
export enum Action {
    RESET_STORE = "Root/RESET_STORE",

    SET_LAYOUT = "UI/SET_LAYOUT",
    SET_NAV_DRAWER_OPEN = "UI/SET_NAV_DRAWER_OPEN",
    SET_IS_ONLINE = "UI/SET_IS_ONLINE",
    UPDATE_NAV_BAR = "UI/UPDATE_NAV_BAR",
    SET_TERRITORY_GROUP_FILTER = "UI/SET_TERRITORY_GROUP_FILTER",

    SET_GEOLOCATION = "Geo/SET_GEOLOCATION",

    // Permissions
    SET_REDUX_EVENT_META = "Permissions/SET_REDUX_EVENT_META",
    SET_CURRENT_USER = "Permissions/SET_CURRENT_USER",
    SET_SERVICE_GROUPS = "Permissions/SET_SERVICE_GROUPS",
    SET_ACTIVE_GROUP = "Permissions/SET_ACTIVE_GROUP",
    SET_USER_ROLES = "Permissions/SET_USER_ROLES",

    // Tags
    SET_ADDRESS_TAGS = "Tags/SET_ADDRESS_TAGS",
    SET_RESULT_TAGS = "Tags/SET_RESULT_TAGS",

    // Territories
    SET_TERRITORIES = "Territories/SET_TERRITORIES",
    UPDATE_TERRITORY = "Territories/UPDATE_TERRITORY",
    DELETE_TERRITORY = "Territories/DELETE_TERRITORY",
    CHECKOUT_TERRITORY = "Territories/CHECKOUT_TERRITORY",
    ASSIGN_TERRITORY = "Territories/ASSIGN_TERRITORY",

    // Territory Groups
    SET_TERRITORY_GROUPS = "TerritoryGroups/SET_TERRITORY_GROUPS",
    UPDATE_TERRITORY_GROUP = "TerritoryGroups/UPDATE_TERRITORY_GROUP",
    DELETE_TERRITORY_GROUP = "TerritoryGroups/DELETE_TERRITORY_GROUP",

    // Publishers
    SET_PUBLISHERS = "Publishers/SET_PUBLISHERS",
    UPDATE_PUBLISHER = "Publishers/UPDATE_PUBLISHER",
    SET_PUBLISHER_PASSWORD = "Publishers/SET_PASSWORD",
    SET_PUBLISHER_ROLES = "Publishers/SET_ROLES",
    DELETE_PUBLISHER = "Publishers/DELETE_PUBLISHER",
    REACTIVATE_PUBLISHER = "Publishers/REACTIVATE_PUBLISHER",

    // Addresses
    CLEAR_ADDRESSES = "Addresses/CLEAR_ADDRESSES",
    APPEND_ADDRESSES = "Addresses/APPEND_ADDRESSES",
    SET_ADDRESSES = "Addresses/SET_ADDRESSES",
    AUTOASSIGN_ADDRESSES = "Addresses/ET_AUTOASSIGN_ADDRESSES",
    UPDATE_ADDRESS = "Addresses/UPDATE_ADDRESS",
    UPDATE_ADDRESS_NOTES = "Addresses/UPDATE_ADDRESS_NOTES",
    GEOCODE_ADDRESS = "Addresses/GEOCODE_ADDRESS",
    DELETE_ADDRESS = "Addresses/DELETE_ADDRESS",

    // Territory Assignments
    SET_ASSIGNMENTS = "Assignments/SET_ASSIGNMENTS",
    UPDATE_ASSIGNMENT = "Assignments/UPDATE_ASSIGNMENT",
    DELETE_ASSIGNMENT = "Assignments/DELETE_ASSIGNMENT",

    // Address Results
    CLEAR_ATTEMPTS = "Addresses/CLEAR_ATTEMPTS",
    APPEND_ATTEMPTS = "Addresses/APPEND_ATTEMPTS",
    SET_ATTEMPTS = "AddressResults/SET_ATTEMPTS",
    RECORD_ADDRESS_ATTEMPT = "AddressResults/RECORD_ATTEMPT",

    // Preferences
    SET_PREF = "Prefs/SET_PREF",
}


/**
 * Tags for categorizing addresses
 */
export enum AddressTags {
    NON_SPEAKER = "NS",
    CONFIRMED_SPEAKER = "X",

    BUSINESS = "BUS",
    RESIDENTIAL = "RES",

    DO_NOT_CALL = "DNC",
    SEX_OFFENDER = "SO",

    DUPLICATE = "DUP",
}


/**
 * Tags for categorizing address visit attempts
 */
export enum ResultTags {
    MAN = "M",
    WOMAN = "W",
    CHILD = "C",

    ADDRESS_NOT_FOUND = "ANF",
    NOT_HOME = "NH",
    NOT_INTERESTED = "NI",
    GATED = "G",
}


/**
 * Status of an address, generally mapped to a display color.
 */
export const enum AddressStatus {
    DO_NOT_CALL = 'do-not-call',
    NON_SPEAKER = 'non-speaker',
    DUPLICATE = 'duplicate',
    UNATTEMPTED = 'unattempted',
    ATTEMPTED = 'attempted',
    COMPLETED = 'completed',
    INACCESSIBLE = 'inaccessible',
}


/**
 * Choices for Address State drop downs
 */
export const STATE_CHOICES = [
    {
        value: "",
        label: "",
    },
    {
        value: "AL",
        label: "Alabama",
    },
    {
        value: "AK",
        label: "Alaska",
    },
    {
        value: "AS",
        label: "American Samoa",
    },
    {
        value: "AZ",
        label: "Arizona",
    },
    {
        value: "AR",
        label: "Arkansas",
    },
    {
        value: "AA",
        label: "Armed Forces Americas",
    },
    {
        value: "AE",
        label: "Armed Forces Europe",
    },
    {
        value: "AP",
        label: "Armed Forces Pacific",
    },
    {
        value: "CA",
        label: "California",
    },
    {
        value: "CO",
        label: "Colorado",
    },
    {
        value: "CT",
        label: "Connecticut",
    },
    {
        value: "DE",
        label: "Delaware",
    },
    {
        value: "DC",
        label: "District of Columbia",
    },
    {
        value: "FL",
        label: "Florida",
    },
    {
        value: "GA",
        label: "Georgia",
    },
    {
        value: "GU",
        label: "Guam",
    },
    {
        value: "HI",
        label: "Hawaii",
    },
    {
        value: "ID",
        label: "Idaho",
    },
    {
        value: "IL",
        label: "Illinois",
    },
    {
        value: "IN",
        label: "Indiana",
    },
    {
        value: "IA",
        label: "Iowa",
    },
    {
        value: "KS",
        label: "Kansas",
    },
    {
        value: "KY",
        label: "Kentucky",
    },
    {
        value: "LA",
        label: "Louisiana",
    },
    {
        value: "ME",
        label: "Maine",
    },
    {
        value: "MD",
        label: "Maryland",
    },
    {
        value: "MA",
        label: "Massachusetts",
    },
    {
        value: "MI",
        label: "Michigan",
    },
    {
        value: "MN",
        label: "Minnesota",
    },
    {
        value: "MS",
        label: "Mississippi",
    },
    {
        value: "MO",
        label: "Missouri",
    },
    {
        value: "MT",
        label: "Montana",
    },
    {
        value: "NE",
        label: "Nebraska",
    },
    {
        value: "NV",
        label: "Nevada",
    },
    {
        value: "NH",
        label: "New Hampshire",
    },
    {
        value: "NJ",
        label: "New Jersey",
    },
    {
        value: "NM",
        label: "New Mexico",
    },
    {
        value: "NY",
        label: "New York",
    },
    {
        value: "NC",
        label: "North Carolina",
    },
    {
        value: "ND",
        label: "North Dakota",
    },
    {
        value: "MP",
        label: "Northern Mariana Islands",
    },
    {
        value: "OH",
        label: "Ohio",
    },
    {
        value: "OK",
        label: "Oklahoma",
    },
    {
        value: "OR",
        label: "Oregon",
    },
    {
        value: "PA",
        label: "Pennsylvania",
    },
    {
        value: "PR",
        label: "Puerto Rico",
    },
    {
        value: "RI",
        label: "Rhode Island",
    },
    {
        value: "SC",
        label: "South Carolina",
    },
    {
        value: "SD",
        label: "South Dakota",
    },
    {
        value: "TN",
        label: "Tennessee",
    },
    {
        value: "TX",
        label: "Texas",
    },
    {
        value: "UT",
        label: "Utah",
    },
    {
        value: "VT",
        label: "Vermont",
    },
    {
        value: "VI",
        label: "Virgin Islands",
    },
    {
        value: "VA",
        label: "Virginia",
    },
    {
        value: "WA",
        label: "Washington",
    },
    {
        value: "WV",
        label: "West Virginia",
    },
    {
        value: "WI",
        label: "Wisconsin",
    },
    {
        value: "WY",
        label: "Wyoming",
    }
];

export const sortByOptions: ITypedSelectOption<IAddressSortOption>[] = [
    {
        value: 'address',
        label: 'Address',
    },
    {
        value: 'proximity',
        label: 'Distance (from your current location)',
    },
];

export const distanceUnitOptions: ITypedSelectOption<IDistanceUnit>[] = [
    {
        value: 'mi',
        label: 'Mile',
    },
    {
        value: 'km',
        label: 'Kilometer',
    },
];

export const mapAppOptions: ITypedSelectOption<IMapApp>[] = [
    {
        value: 'apple',
        label: 'Apple Maps',
    },
    {
        value: 'google-app',
        label: 'Google Maps (Mobile App)',
    },
    {
        value: 'google-web',
        label: 'Google Maps (Web)',
    },
];


export const TERRITORY_OVERDUE_DAYS = (4 * 30);  // 4 months
