import React from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {
    IconName,
    Classes,
    Dialog as BlueprintDialog,
} from '@blueprintjs/core';
import {Layout} from '../../constants';
import {IReduxState} from '../../reducers.interfaces';


interface IOwnProps {
    isOpen?: boolean;
    fullBleed?: boolean;
    target?: JSX.Element;
    title?: React.ReactNode;
    icon?: IconName;
    children: JSX.Element | JSX.Element[] | null;
    onRequestClose?: () => void;
    onSubmit?: () => void;
}

interface IReduxProps {
    layout: Layout;
}

interface IContainerProps extends IOwnProps, IReduxProps {}


interface IContainerState {
    isOpen: boolean;
}


interface IComponentProps {
    layout: Layout;
    isOpen: boolean;
    target: JSX.Element | null;
    title: React.ReactNode | undefined;
    icon: IconName | undefined;
    children: JSX.Element | JSX.Element[] | null;
    onTargetClick: () => void;
    onRequestClose: () => void;
}


class FormDialogComponent extends React.PureComponent<IComponentProps, {}> {
    render () {
        const dialogClasses = classNames({
            [`${Classes.DIALOG}--form-dialog`]: true,
        });
        const bodyClasses = classNames({
            [Classes.DIALOG_BODY]: true,
            [`${Classes.DIALOG_BODY}--form-dialog`]: true
        });
        return (
            <>
                {this.props.target}
                <BlueprintDialog
                    className={dialogClasses}
                    isOpen={this.props.isOpen}
                    hasBackdrop={true}
                    onClose={this.props.onRequestClose}
                    title={this.props.title}
                    icon={this.props.icon}
                >
                    <div className={bodyClasses}>
                        {this.props.children}
                    </div>
                </BlueprintDialog>
            </>
        );
    }
}



class FormDialogContainer extends React.PureComponent<IContainerProps, IContainerState> {

    state: IContainerState = {
        isOpen: false,
    };


    private readonly onTargetClick = () => {
        window._paq.push(['trackEvent', 'FormDialog', 'onTargetClick']);
        this.setState({ isOpen: true, });
    }


    private readonly onRequestClose = () => {
        window._paq.push(['trackEvent', 'FormDialog', 'onRequestClose']);
        this.setState({ isOpen: false, });
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    }


    componentDidUpdate (nextProps: IContainerProps) {
        if (nextProps.layout !== this.props.layout) {
            this.onRequestClose();
        }
    }


    private getIsOpen () {
        if (this.props.isOpen === undefined) {
            return this.state.isOpen;
        }
        return this.props.isOpen;
    }


    private buildTarget () {
        if (!this.props.target) {
            return null;
        }
        // Open/Closed status is being controlled by the outside component.
        if (this.props.isOpen !== undefined) {
            return this.props.target;
        }
        // Open/Closed status is being controlled by us.
        const targetProps: React.HTMLProps<HTMLElement> = {
            onClick: this.onTargetClick,
        };
        const clonedTarget: JSX.Element = React.cloneElement(this.props.target, targetProps);
        return clonedTarget;
    }


    render () {
        return (
            <FormDialogComponent
                layout={this.props.layout}
                isOpen={this.getIsOpen()}
                target={this.buildTarget()}
                title={this.props.title}
                icon={this.props.icon}
                children={this.props.children}
                onTargetClick={this.onTargetClick}
                onRequestClose={this.onRequestClose}
            />
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IContainerProps => {
    return {
        layout: state.ui.layout,
        ...ownProps,
    };
};

export const FormDialog = connect(mapStateToProps)(FormDialogContainer);
