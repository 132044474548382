import {
    IPublisher,
    ITerritory,
    ITerritoryGroup,
    IAddress,
    ITerritoryAssignment,
} from "./models.interfaces";

export type IViewName =
    | "territory-address-list"
    | "territory-address-print"
    | "territory-history-list"
    | "territory-checkout"
    | "territory-map"
    | "territory-list"
    | "territory-group-create"
    | "territory-group-edit"
    | "territory-group-list"
    | "publisher-create"
    | "publisher-edit"
    | "publisher-set-password"
    | "publisher-set-roles"
    | "publisher-list"
    | "address-create"
    | "address-edit"
    | "address-list"
    | "territory-assignment-list"
    | "territory-assignment-checkin"
    | "my-territory-list"
    | "share-territory"
    | "work-territory"
    | "audit-log"
    | "statistics"
    | "import-address-data"
    | "login"
    | "update-auth-token"
    | "home";

export type IViewData = { readonly [name in IViewName]: string };

interface IPatternData {
    readonly [s: string]: string | number | null;
}

const patterns: IViewData = {
    "territory-address-list": "/territories/:id/addresses/",
    "territory-address-print": "/territories/:id/print/",
    "territory-history-list": "/territories/:id/history/",
    "territory-checkout": "/territories/:id/checkout/",
    "territory-map": "/territory-map/",
    "territory-list": "/territories/",

    "territory-group-create": "/territory-groups/create/",
    "territory-group-edit": "/territory-groups/:id/edit/",
    "territory-group-list": "/territory-groups/",

    "publisher-create": "/publishers/create/",
    "publisher-edit": "/publishers/:id/edit/",
    "publisher-set-password": "/publishers/:id/set-password/",
    "publisher-set-roles": "/publishers/:id/set-roles/",
    "publisher-list": "/publishers/",

    "address-create": "/addresses/create/",
    "address-edit": "/addresses/:id/edit/",
    "address-list": "/addresses/",

    "territory-assignment-list": "/assignments/",
    "territory-assignment-checkin": "/assignments/:id/check-in/",

    "share-territory": "/my-territories/:id/share/",
    "work-territory": "/my-territories/:id/work/",
    "my-territory-list": "/my-territories/",

    "audit-log": "/audit-log/",

    "statistics": "/statistics/",

    "import-address-data": "/import/addresses/",

    "login": "/auth/login/",
    "update-auth-token": "/auth/update/:token/",

    "home": "/",
};

export const getPattern = function(view: IViewName): string {
    return patterns[view];
};

const reversePattern = function(view: IViewName, params: IPatternData) {
    const pattern = getPattern(view);
    return pattern.replace(/(\:[^/]+)/g, (prefixedName) => {
        const name = prefixedName.replace(/^\:/, "");
        const value = params[name];
        if (value === null || value === undefined) {
            throw new Error(`Reverse pattern data is missing URL argument: ${name}`);
        }
        return `${value}`;
    });
};

export const reverse = {
    territoryAddressList: (territory: ITerritory) => {
        return reversePattern("territory-address-list", {
            id: territory.id,
        });
    },

    territoryAddressPrint: (territory: ITerritory) => {
        return reversePattern("territory-address-print", {
            id: territory.id,
        });
    },

    territoryHistoryList: (territory: ITerritory) => {
        return reversePattern("territory-history-list", {
            id: territory.id,
        });
    },

    territoryCheckout: (territory: ITerritory) => {
        return reversePattern("territory-checkout", {
            id: territory.id,
        });
    },

    territoryMap: () => {
        return reversePattern("territory-map", {});
    },

    territoryList: () => {
        return reversePattern("territory-list", {});
    },

    territoryGroupCreate: () => {
        return reversePattern("territory-group-create", {});
    },

    territoryGroupEdit: (group: ITerritoryGroup) => {
        return reversePattern("territory-group-edit", {
            id: group.id,
        });
    },

    territoryGroupList: () => {
        return reversePattern("territory-group-list", {});
    },

    publisherCreate: () => {
        return reversePattern("publisher-create", {});
    },

    publisherEdit: (publisher: IPublisher) => {
        return reversePattern("publisher-edit", {
            id: publisher.id,
        });
    },

    publisherSetPassword: (publisher: IPublisher) => {
        return reversePattern("publisher-set-password", {
            id: publisher.id,
        });
    },

    publisherSetRoles: (publisher: IPublisher) => {
        return reversePattern("publisher-set-roles", {
            id: publisher.id,
        });
    },

    publisherList: () => {
        return reversePattern("publisher-list", {});
    },

    addressCreate: () => {
        return reversePattern("address-create", {});
    },

    addressEdit: (address: IAddress) => {
        return reversePattern("address-edit", {
            id: address.id,
        });
    },

    addressList: () => {
        return reversePattern("address-list", {});
    },

    territoryAssignmentList: () => {
        return reversePattern("territory-assignment-list", {});
    },

    territoryAssignmentCheckin: (assignment: ITerritoryAssignment) => {
        return reversePattern("territory-assignment-checkin", {
            id: assignment.id,
        });
    },

    shareTerritory: (territory: ITerritory) => {
        return reversePattern("share-territory", {
            id: territory.id,
        });
    },

    workTerritory: (territory: ITerritory) => {
        return reversePattern("work-territory", {
            id: territory.id,
        });
    },

    myTerritoryList: () => {
        return reversePattern("my-territory-list", {});
    },

    auditLog: () => {
        return reversePattern("audit-log", {});
    },

    statistics: () => {
        return reversePattern("statistics", {});
    },

    importAddressData: () => {
        return reversePattern("import-address-data", {});
    },

    login: () => {
        return reversePattern("login", {});
    },

    updateAuthToken: (token: string) => {
        return reversePattern("update-auth-token", {
            token: token,
        });
    },

    home: () => {
        return reversePattern("home", {});
    },
};
