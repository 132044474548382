import React from 'react';
import {IconName} from "@blueprintjs/core";
import {ISelectOption} from "../../forms.interfaces";
import {FormGroup} from "../wrappers/FormGroup";
import {InputGroupType, InputGroup} from "../wrappers/InputGroup";


export interface IProps {
    classPrefix: string;
    name: string;
    label: string;
    helpText?: string;

    autocomplete?: string;
    icon?: IconName;
    large?: boolean;
    disabled?: boolean;
    inline?: boolean;

    options: ISelectOption[];
    value: string;
    errors?: string[];
    invalid?: boolean;

    selectRef?: (r: HTMLSelectElement) => void;
    onChange?: (e: React.FormEvent<HTMLSelectElement>) => void;
}


interface IState {
}


export class Select extends React.PureComponent<IProps, IState> {
    render () {
        const inputID = `${this.props.classPrefix}__${this.props.name}`;

        const optionElems = this.props.options.map((opt) => {
            return (
                <option key={opt.value}
                        value={opt.value}
                        disabled={opt.disabled}
                        title={opt.title}>
                    {opt.label}
                </option>
            );
        });

        return (
            <FormGroup inputID={inputID}
                       label={this.props.label}
                       helpText={this.props.helpText}
                       invalid={this.props.invalid}
                       errors={this.props.errors}
                       inline={this.props.inline}>
                <InputGroup type={InputGroupType.SELECT}
                            icon={this.props.icon}
                            large={this.props.large}
                            invalid={this.props.invalid}>
                    <select id={inputID}
                            name={this.props.name}
                            ref={this.props.selectRef}
                            value={this.props.value}
                            onChange={this.props.onChange}
                            disabled={this.props.disabled}>
                        {optionElems}
                    </select>
                </InputGroup>
            </FormGroup>
        );
    }
}
