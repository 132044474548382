import {IGeoActions} from "../actions.interfaces";
import {Action} from "../constants";
import {IReduxState} from "../reducers.interfaces";
// import {guardUnhandledAction} from './utils';

const reducerGeolocation = function(state: IReduxState["geolocation"] = null, action: IGeoActions) {
    switch (action.type) {
        case Action.SET_GEOLOCATION:
            return action.payload;

        // default:
        // guardUnhandledAction(action);
    }

    return state;
};

export default reducerGeolocation;
