import {IPrefActions} from "../actions.interfaces";
import {Action} from "../constants";
import {defaultReduxState} from "../defaults";
import {IUserPrefs} from "../models.interfaces";
// import {guardUnhandledAction} from "./utils";

const setPrefs = function(current: IUserPrefs, updates: Partial<IUserPrefs>) {
    return {...current, ...updates};
};

const reducerPrefs = function(state = defaultReduxState.prefs, action: IPrefActions) {
    switch (action.type) {
        case Action.SET_PREF:
            return setPrefs(state, action.payload);

        // default:
        //     guardUnhandledAction(action);
    }

    return state;
};

export default reducerPrefs;
