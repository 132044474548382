export const AUTH_TOKEN = "baruch-auth-token";


export const setAuthToken = async (value: string): Promise<void> => {
    localStorage.setItem(AUTH_TOKEN, value);
};


export const getAuthToken = async (): Promise<string> => {
    return localStorage.getItem(AUTH_TOKEN) || '';
};
