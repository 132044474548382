import {IconName} from "@blueprintjs/core";
import {RoleName} from "./constants";
import {reverse} from "./urls";

export interface ILink {
    label: string;
    icon: IconName;
    url: string;
    role: RoleName;
}

export interface ILinkGroup {
    label: string | null;
    icon: IconName | null;
    links: ILink[];
}

export const NAV_BAR_LINKS: ILinkGroup[] = [
    {
        label: null,
        icon: null,
        links: [
            {
                label: "Home",
                icon: "home",
                url: reverse.home(),
                role: RoleName.PUBLISHER,
            },
        ],
    },
    {
        label: "Field Ministry",
        icon: "briefcase",
        links: [
            {
                label: "My Territories",
                icon: "drive-time",
                url: reverse.myTerritoryList(),
                role: RoleName.PUBLISHER,
            },
        ],
    },
    {
        label: "Administration",
        icon: "wrench",
        links: [
            {
                label: "Territories",
                icon: "application",
                url: reverse.territoryList(),
                role: RoleName.GROUP_ADMIN,
            },
            {
                label: "Territory Borders",
                icon: "map",
                url: reverse.territoryMap(),
                role: RoleName.GROUP_ADMIN,
            },
            {
                label: "Groupings",
                icon: "applications",
                url: reverse.territoryGroupList(),
                role: RoleName.GROUP_ADMIN,
            },
            {
                label: "Publishers",
                icon: "people",
                url: reverse.publisherList(),
                role: RoleName.GROUP_ADMIN,
            },
            {
                label: "Addresses",
                icon: "id-number",
                url: reverse.addressList(),
                role: RoleName.GROUP_ADMIN,
            },
            {
                label: "Assignments",
                icon: "send-to-map",
                url: reverse.territoryAssignmentList(),
                role: RoleName.GROUP_ADMIN,
            },
            {
                label: "Audit Log",
                icon: "property",
                url: reverse.auditLog(),
                role: RoleName.GROUP_ADMIN,
            },
        ],
    },
    {
        label: "Data Import / Export",
        icon: "satellite",
        links: [
            {
                label: "Statistics",
                icon: "pie-chart",
                url: reverse.statistics(),
                role: RoleName.GROUP_ADMIN,
            },
            {
                label: "Import Addresses",
                icon: "id-number",
                url: reverse.importAddressData(),
                role: RoleName.GROUP_ADMIN,
            },
        ],
    },
];
