import * as Sentry from '@sentry/browser';
import {Plugins} from '@capacitor/core';
import {v4 as uuidv4} from 'uuid';
import {Intent} from '@blueprintjs/core';
import {Subject} from 'rxjs';
import {WebSocketBridge} from "./channels";
import {AlertType} from "./constants";
import {ISyncedAction, IBaruchServerAction} from "./actions.interfaces";
import {BaruchServerActions, RXServerEvent, isServerStatus2xx, isServerStatus3xx, isServerStatus4xx} from "./actions.codecs";
import {resetStore, setReduxEventMeta, setIsOnline} from "./dispatchers";
import {check, ReduxEventMetaArray} from "./models";
import {store} from "./store";
import {getLatestEventID} from "./reducers";
import {getAuthToken} from "./utils/auth";
import {API_BASE, getAPIClient} from "./utils/ajax";
import {recordTiming, recordSyncTiming} from "./utils/analytics";
import {Alerts} from './toasts';

const {Storage} = Plugins;


class ServerSync {
    public readonly eventStream: Subject<IBaruchServerAction>;

    private readonly serverEventIDs: string[] = [];
    private readonly eventSequenceKey: string;
    private readonly webSocketBridge: WebSocketBridge;
    private lastAlertDisplayed: AlertType | null = null;
    private initSyncCalled = false;
    private resumeSyncCalled = false;


    constructor (eventSeqKey: string) {
        this.eventStream = new Subject<IBaruchServerAction>();

        this.eventSequenceKey = eventSeqKey;
        this.webSocketBridge = new WebSocketBridge();
    }


    /**
     * Handle the websocket opened event
     */
    private readonly handleWebSocketOpened = () => {
        setIsOnline(true);
        this.showOnlineAlert();
        // This prevents calling resumeSync until the application explicitly starts the sync process at least once.
        if (this.initSyncCalled || this.resumeSyncCalled) {
            this.resumeSync();
        }
    }


    /**
     * Handle the websocket disconnected event
     */
    private readonly handleWebSocketClosed = () => {
        setIsOnline(false);
        this.showOfflineAlert();
    }


    /**
     * Handle a Redux event received from the server
     */
    private readonly handleServerEvent = (msg: unknown) => {
        try {
            const event = check(RXServerEvent.decode(msg));
            if (isServerStatus4xx(event)) {
                this.showUnauthenticatedAlert();
                resetStore();
                return;
            }
            if (isServerStatus2xx(event) || isServerStatus3xx(event)) {
                this.dispatchReduxEvent(event.data);
                return;
            }
        } catch (err) {
            Sentry.captureException(err);
        }
        this.showUnexpectedErrorAlert();
    }



    /**
     * Connect to the websocket and listen for server events
     */
    public async listen (wsURL: string) {
        const token: string = await getAuthToken();
        this.webSocketBridge.connect(`${wsURL}?token=${token}`);
        this.webSocketBridge.listen(this.handleServerEvent);
        if (!this.webSocketBridge.socket) {
            throw new Error('Websocket connection failed to initialize');
        }
        this.webSocketBridge.socket.addEventListener('open', this.handleWebSocketOpened);
        this.webSocketBridge.socket.addEventListener('close', this.handleWebSocketClosed);
        this.webSocketBridge.socket.addEventListener('error', this.handleWebSocketClosed);
    }


    /**
     * Fetch the initial application data
     */
    @recordTiming
    public async initSync () {
        this.initSyncCalled = true;
        const actions = await this.getInitialData();
        actions.forEach((action) => {
            this.dispatchReduxEvent(action);
        });
        const eventMeta = await this.loadReduxEventMetadata();
        const state = store.getState();
        setReduxEventMeta(state.permissions.active_service_group, eventMeta);
    }


    /**
     * Resume sync, taking into account data from redux-persist
     */
    @recordTiming
    public async resumeSync () {
        this.resumeSyncCalled = true;
        const lastSeq = await this.getLastEventSequence();
        console.log(`Resuming sync process. Last processed event has sequence ${lastSeq}`);
        if (!lastSeq) {
            return this.initSync();
        }
        const actions = await this.loadEvents(lastSeq);
        // Resuming sync sometimes causes out-of-memory issues, when the client is very behind.
        // So, if we're more than 10 events behind. just start from scratch.
        if (actions.length > 10) {
            return this.initSync();
        }
        actions.forEach((action) => {
            this.dispatchReduxEvent(action);
        });
    }


    /**
     * Send a Redux event to the server
     */
    @recordTiming
    public async sendServerEvent (event: ISyncedAction, background = false, enableErrorToast = true) {
        if (!event.type) {
            console.warn("Refusing to send event to server because it is missing an event type");
            return;
        }
        // Generate event IDs
        const parentEventID = getLatestEventID();
        const syncedParentEventID = (this.serverEventIDs.length > 0)
            ? this.serverEventIDs[this.serverEventIDs.length - 1]
            : null;
        const eventID = uuidv4();
        // Build the event object
        const txEvent: ISyncedAction = {
            ...event,
            meta: {
                ...event.meta,
                event_id: eventID,
                parent_event_id: parentEventID,
                synced_parent_event_id: syncedParentEventID,
            },
        };
        // Send Sentry Breadcrumb
        Sentry.addBreadcrumb({
            category: 'redux.send-server-event',
            level: Sentry.Severity.Info,
            message: `Sending Redux action to server: ${txEvent.type}[${eventID}], background=${background}`,
            data: {
                action: txEvent,
            },
        });
        // If the caller allows the event to be backgrounded, dispatch the event immediately.
        // The event UUIDs and the reducer rebase logic will take care of re-ordering and
        // de-duplicating events
        // if (background) {
        //     this.dispatchReduxEvent(txEvent);
        // }
        // Send the event to the server
        const api = await getAPIClient();
        try {
            const resp = await api
                .post(`/redux/events/`)
                .send(txEvent);
            return check(BaruchServerActions.decode(resp.body));
        } catch (error) {
            if (error.response.forbidden) {
                this.showUnauthenticatedAlert();
                resetStore();
            } else if (enableErrorToast) {
                this.showUnexpectedErrorAlert();
            }
            throw error;
        }
    }


    /**
     * Show an unexpected error alert to the user
     */
    private showUnexpectedErrorAlert () {
        Alerts.show({
            message: "An unexpected error occurred.",
            icon: 'error',
            intent: Intent.DANGER,
        });
    }


    /**
     * Show an alert to the user showing that they're now online
     */
    private showOnlineAlert () {
        if (this.lastAlertDisplayed !== AlertType.OFFLINE) {
            return;
        }
        this.lastAlertDisplayed = AlertType.ONLINE;
        Alerts.show({
            message: "Connected to server. You are now online.",
            icon: 'offline',
            intent: Intent.SUCCESS,
        });
    }


    /**
     * Show an alert to the user showing that they're now offline
     */
    private showOfflineAlert () {
        if (this.lastAlertDisplayed === AlertType.OFFLINE) {
            return;
        }
        this.lastAlertDisplayed = AlertType.OFFLINE;
        Alerts.show({
            message: "Server connection lost. You are now offline.",
            icon: 'offline',
            intent: Intent.DANGER,
        });
    }


    /**
     * Show an alert to the user showing that they need to log-in
     */
    private showUnauthenticatedAlert () {
        if (this.lastAlertDisplayed === AlertType.UNAUTHENTICATED) {
            return;
        }
        this.lastAlertDisplayed = AlertType.UNAUTHENTICATED;
        Alerts.show({
            message: "You must login to continue.",
            icon: 'log-in',
            intent: Intent.WARNING,
        });
    }


    /**
     * Get the sequence number of the last event received from the server
     */
    @recordTiming
    public async getLastEventSequence () {
        const sequence = (await Storage.get({ key: this.eventSequenceKey })).value;
        return sequence ? parseInt(sequence, 10) : 0;
    }


    /**
     * Set the sequence number of the last event received from the server
     */
    @recordTiming
    private async setLastEventSequence (sequence: number) {
        await Storage.set({
            key: this.eventSequenceKey,
            value: `${sequence}`,
        });
        console.log(`Set ${this.eventSequenceKey} to ${sequence}`);
        return sequence;
    }


    /**
     * Load metadata about Redux event types and permissions
     */
    @recordTiming
    private async loadReduxEventMetadata () {
        const api = await getAPIClient();
        const resp = await api.get(`/redux/event-types/`);
        return check(ReduxEventMetaArray.decode(resp.body));
    }


    /**
     * Get initial event data from the server
     */
    @recordTiming
    private async getInitialData () {
        const api = await getAPIClient();
        const resp = await api.get(`/redux/initial-data/`);
        return check(BaruchServerActions.decode(resp.body));
    }


    /**
     * Get event data from the server starting with the given sequence number
     */
    @recordTiming
    public async loadEvents (startSequence: number, limit: number | null = null, reverse = false) {
        const api = await getAPIClient();
        const resp = await api
            .get(`/redux/events/`)
            .query({
                sequence: startSequence,
                limit: limit,
                reverse: reverse ? '1' : '',
            });
        return check(BaruchServerActions.decode(resp.body));
    }


    /**
     * Dispatch a Redux event into the Redux store
     */
    @recordSyncTiming
    private dispatchReduxEvent (action: ISyncedAction) {
        const eventID = action.meta && action.meta.event_id ? action.meta.event_id : null;
        // Add Sentry Breadcrumb
        Sentry.addBreadcrumb({
            category: 'redux.dispatch-from-server',
            level: Sentry.Severity.Info,
            message: `Received Redux action from server: ${action.type}[${eventID}]`,
            data: {
                action: action,
            },
        });
        // Store event ID to mark that we've successfully gotten this event from the server
        if (eventID && !this.serverEventIDs.includes(eventID)) {
            this.serverEventIDs.push(eventID);
        }
        // Dispatch the action to Redux and other subscribers
        this.eventStream.next(action);
        store.dispatch(action);
        // Save the action offset so we know where to pick up when we resume the next sync
        if (action.id) {
            this.setLastEventSequence(action.id);
        }
    }

}


export const sync = new ServerSync('baruch-sync-sequence');
export default sync;

sync.listen(`${API_BASE.replace('https', 'wss')}/ws/redux/`);
