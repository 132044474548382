export const chunk = function<T>(arr: T[], size: number): T[][] {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) => {
        return arr.slice(i * size, i * size + size);
    });
};


/**
 * Group an array of objects by some property or value
 */
export const groupBy = function<T>(arr: T[], getKey: (obj: T) => (string | number)): T[][] {
    type Groups = { [key: string]: T[]; };
    const groups = arr.reduce<Groups>((g, obj) => {
        const key = getKey(obj);
        if (!g.hasOwnProperty(key)) {
            g[key] = [];
        }
        g[key].push(obj);
        return g;
    }, {});
    return Object.values(groups);
};


/**
 * Return an array of the given length
 */
export const range = function(x: number) {
    const arr: unknown[] = Array.apply(null, Array(x));
    const rng = arr.map((_, i) => { return i + 1; });
    return rng;
};


/**
 * Array filter predicate for removing null and undefined values from a list
 */
export const notEmpty = function<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined;
};


/**
 * Array filter predicate used to make the array unique
 */
export const unique = function<T>(value: T, index: number, arr: T[]) {
    return arr.indexOf(value) === index;
};


/**
 * Make an array unique by the given property of each object in the array
 */
export const uniqueByProp = function<T extends {}>(list: T[], prop: keyof T): T[] {
    const values = new Set();
    return list.filter((item) => {
        const value = item[prop];
        if (values.has(value)) {
            return false;
        }
        values.add(value);
        return true;
    });
};


/**
 * Extract an Object's keys and return them as an array
 *
 * Same as Object.keys, but with a slightly tighter type definition.
 * See https://github.com/Microsoft/TypeScript/pull/12253#issuecomment-393954723
 */
export const objectKeys = Object.keys as <T>(o: T) => (Extract<keyof T, string>)[];


/**
 * Type-safe partial function application.
 *
 * Example:
 *
 * > const logMessage = function(level: string, message: string) {
 * >     console.log(`${level}: ${message}`);
 * > };
 * >
 * > logMessage('INFO', 'Hello, World.');  // Output: 'INFO: Hello, World.'
 * >
 * > const logInfo = papply(logMessage, 'INFO');
 * >
 * > logInfo('Hello, World.');  // Output: 'INFO: Hello, World.'
 *
 */
// tslint:disable-next-line no-any
export const papply = function<T, U extends any[], V>(fn: (x: T, ...args: U) => V, x: T): (...args: U) => V {
    return (...args: U) => {
        return fn(x, ...args);
    };
};
