import superagent from "superagent";
import {getAuthToken} from './auth';


export interface IAPIError extends Error {
    response: superagent.Response;
    status: number;
}


export const API_BASE = process.env.API_BASE || '';


class APIClient {
    private readonly authToken: string | null = null;

    constructor (authToken?: string) {
        this.authToken = authToken || null;
    }

    public readonly get = (path: string) => {
        const auth = this.getAuthHeaderValue();
        return superagent
            .get(`${API_BASE}${path}`)
            .set("Accept", "application/json")
            .set("Authorization", auth);
    }

    public readonly post = (path: string, disableAuthToken = false) => {
        const auth = this.getAuthHeaderValue();
        let req = superagent
            .post(`${API_BASE}${path}`)
            .set("Accept", "application/json");
        if (!disableAuthToken) {
            req = req.set("Authorization", auth);
        }
        return req;
    }

    public readonly delete = (path: string) => {
        const auth = this.getAuthHeaderValue();
        return superagent
            .delete(`${API_BASE}${path}`)
            .set("Accept", "application/json")
            .set("Authorization", auth);
    }

    public readonly options = (path: string) => {
        const auth = this.getAuthHeaderValue();
        return superagent
            .options(`${API_BASE}${path}`)
            .set("Accept", "application/json")
            .set("Authorization", auth);
    }

    private getAuthHeaderValue () {
        return this.authToken
            ? `Token ${this.authToken}`
            : '';
    }
}


export const getAPIClient = async () => {
    const authToken = await getAuthToken();
    return new APIClient(authToken);
};
