import {
    Options,
    default as ReconnectingWebSocket,
} from "reconnecting-websocket";


type ListenCallback = (action: unknown) => void;


export class WebSocketBridge {

    socket: ReconnectingWebSocket | null = null;


    public connect (url: string, protocols?: string | string[], options?: Options) {
        let _url;
        // Use wss:// if running on https://
        const scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
        const base_url = `${scheme}://${window.location.host}`;
        if (url === undefined) {
            _url = base_url;
        } else {
            // Support relative URLs
            if (url[0] === '/') {
                _url = `${base_url}${url}`;
            } else {
                _url = url;
            }
        }
        this.socket = new ReconnectingWebSocket(_url, protocols, options);
    }


    public listen (cb: ListenCallback) {
        if (!this.socket) {
            throw new Error('Socket is not connected');
        }
        this.socket.onmessage = (event) => {
            const msg = (JSON.parse(event.data) as unknown);
            cb(msg);
        };
    }


    public send <T>(msg: T) {
        if (!this.socket) {
            throw new Error('Socket is not connected');
        }
        this.socket.send(JSON.stringify(msg));
    }
}
