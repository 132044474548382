import * as t from 'io-ts';
import {isRight} from 'fp-ts/lib/Either';
import {Layout, Action as ActionType} from "./constants";
import {
    nullable,
    createEnumType,
    JSONDate,
    DOMPosition,
    Address,
    AddressNotes,
    AddressAttempt,
    AddressLoaderResponse,
    AddressTag,
    Publisher,
    ReduxEventMeta,
    ResultTag,
    RolesAPIResponse,
    ServiceGroup,
    Territory,
    TerritoryAssignment,
    TerritoryGroup,
    User,
    UserPrefs,
    UserRole,
} from './models';



const ActionMeta = t.partial({
    // The ID of the client's active service group at the time this event
    // was created.
    service_group: nullable(t.number),
    // A client-assigned UUID describing this event.
    event_id: nullable(t.string),
    // The event_id of the event immediately previous to this event on the
    // client at the time this event was created.
    parent_event_id: nullable(t.string),
    // The event_id of the last event the client had received from the server at
    // the time this event was created.
    synced_parent_event_id: nullable(t.string),
});

const AbstractBaseAction = <T extends ActionType>(type: T) => {
    return t.interface({
        type: t.literal(type),
    });
};

const AbstractUnsyncedAction = <T extends ActionType, P extends t.Mixed>(type: T, payload: P) => {
    return t.intersection([
        AbstractBaseAction(type),
        t.interface({
            payload: payload,
        }),
        t.partial({
            error: t.boolean,
        }),
    ]);
};

const AbstractSyncedAction = <T extends ActionType, P extends t.Mixed>(type: T, payload: P) => {
    return t.intersection([
        AbstractUnsyncedAction(type, payload),
        t.interface({
            meta: nullable(ActionMeta),
        }),
        t.partial({
            id: nullable(t.number),
        }),
    ]);
};



// ============================================================================
// Root-level Actions
// ============================================================================
export const ResetStore = AbstractBaseAction(ActionType.RESET_STORE);

export const NullAction = t.interface({
    id: t.number,
    type: t.null,
    author: t.null,
    meta: t.null,
    payload: t.null,
    error: t.null,
});


// ============================================================================
// UI Actions
// ============================================================================
export const SetLayout = AbstractUnsyncedAction(ActionType.SET_LAYOUT, createEnumType<Layout>(Layout, 'Layout'));

export const SetNavDrawerOpen = AbstractUnsyncedAction(ActionType.SET_NAV_DRAWER_OPEN, t.boolean);

export const SetIsOnline = AbstractUnsyncedAction(ActionType.SET_IS_ONLINE, t.boolean);

export const UpdateNavBar = AbstractUnsyncedAction(ActionType.UPDATE_NAV_BAR, t.interface({
    getNavBarTitle: t.Function,
    getNavBarContentLeft: t.Function,
    getNavBarContentRight: t.Function,
}));

export const SetTerritoryGroupFilter = AbstractUnsyncedAction(ActionType.SET_TERRITORY_GROUP_FILTER, nullable(t.number));


// ============================================================================
// Geo-location Actions
// ============================================================================
export const SetGeoLocation = AbstractUnsyncedAction(ActionType.SET_GEOLOCATION, DOMPosition);


// ============================================================================
// User Permission Actions
// ============================================================================
export const SetReduxEventMeta = AbstractSyncedAction(ActionType.SET_REDUX_EVENT_META, t.array(ReduxEventMeta));

export const SetCurrentUser = AbstractSyncedAction(ActionType.SET_CURRENT_USER, nullable(User));

export const SetServiceGroups = AbstractSyncedAction(ActionType.SET_SERVICE_GROUPS, t.array(ServiceGroup));

export const SetActiveGroup = AbstractSyncedAction(ActionType.SET_ACTIVE_GROUP, t.interface({
    id: t.number,
}));

export const SetUserRoles = AbstractSyncedAction(ActionType.SET_USER_ROLES, t.array(UserRole));


// ============================================================================
// Tag Actions
// ============================================================================
export const SetAddressTags = AbstractSyncedAction(ActionType.SET_ADDRESS_TAGS, t.array(AddressTag));

export const SetResultTags = AbstractSyncedAction(ActionType.SET_RESULT_TAGS, t.array(ResultTag));


// ============================================================================
// Territory Actions
// ============================================================================
export const SetTerritories = AbstractSyncedAction(ActionType.SET_TERRITORIES, t.array(Territory));

export const UpdateTerritory = AbstractSyncedAction(ActionType.UPDATE_TERRITORY, Territory);

export const DeleteTerritory = AbstractSyncedAction(ActionType.DELETE_TERRITORY, t.interface({
    id: t.number,
}));

export const CheckoutTerritory = AbstractSyncedAction(ActionType.CHECKOUT_TERRITORY, t.interface({
    territory: t.number,
    publisher: t.number,
    date_out: JSONDate,
}));

export const AssignTerritory = AbstractSyncedAction(ActionType.ASSIGN_TERRITORY, TerritoryAssignment);


// ============================================================================
// Territory Group Actions
// ============================================================================
export const SetTerritoryGroups = AbstractSyncedAction(ActionType.SET_TERRITORY_GROUPS, t.array(TerritoryGroup));

export const UpdateTerritoryGroup = AbstractSyncedAction(ActionType.UPDATE_TERRITORY_GROUP, TerritoryGroup);

export const DeleteTerritoryGroup = AbstractSyncedAction(ActionType.DELETE_TERRITORY_GROUP, t.interface({
    id: t.number,
}));


// ============================================================================
// Publisher / User Actions
// ============================================================================
export const SetPublishers = AbstractSyncedAction(ActionType.SET_PUBLISHERS, t.array(Publisher));

export const UpdatePublisher = AbstractSyncedAction(ActionType.UPDATE_PUBLISHER, Publisher);

export const SetPublisherPassword = AbstractSyncedAction(ActionType.SET_PUBLISHER_PASSWORD, t.interface({
    id: t.number,
    password: t.string,
}));

export const SetPublisherRoles = AbstractSyncedAction(ActionType.SET_PUBLISHER_ROLES, t.interface({
    service_group: t.number,
    user: t.number,
    roles: RolesAPIResponse,
}));

export const DeletePublisher = AbstractSyncedAction(ActionType.DELETE_PUBLISHER, t.interface({
    id: t.number,
}));

export const ReactivatePublisher = AbstractSyncedAction(ActionType.REACTIVATE_PUBLISHER, t.interface({
    id: t.number,
}));


// ============================================================================
// Address Actions
// ============================================================================
export const ClearAddresses = AbstractSyncedAction(ActionType.CLEAR_ADDRESSES, t.null);

export const AppendAddresses = AbstractSyncedAction(ActionType.APPEND_ADDRESSES, t.array(Address));

export const SetAddresses = AbstractSyncedAction(ActionType.SET_ADDRESSES, AddressLoaderResponse);

export const AutoAssignAddresses = AbstractSyncedAction(ActionType.AUTOASSIGN_ADDRESSES, t.null);

export const UpdateAddress = AbstractSyncedAction(ActionType.UPDATE_ADDRESS, Address);

export const UpdateAddressNotes = AbstractSyncedAction(ActionType.UPDATE_ADDRESS_NOTES, AddressNotes);

export const GeocodeAddress = AbstractSyncedAction(ActionType.GEOCODE_ADDRESS, t.interface({
    id: t.number,
}));

export const DeleteAddress = AbstractSyncedAction(ActionType.DELETE_ADDRESS, t.interface({
    id: t.number,
}));


// ============================================================================
// Territory Assignment Actions
// ============================================================================
export const SetAssignments = AbstractSyncedAction(ActionType.SET_ASSIGNMENTS, t.array(TerritoryAssignment));

export const UpdateAssignment = AbstractSyncedAction(ActionType.UPDATE_ASSIGNMENT, TerritoryAssignment);

export const DeleteAssignment = AbstractSyncedAction(ActionType.DELETE_ASSIGNMENT, t.interface({
    id: t.number,
}));


// ============================================================================
// Address Attempt Actions
// ============================================================================
export const ClearAttempts = AbstractSyncedAction(ActionType.CLEAR_ATTEMPTS, t.null);

export const AppendAttempts = AbstractSyncedAction(ActionType.APPEND_ATTEMPTS, t.array(AddressAttempt));

export const SetAttempts = AbstractSyncedAction(ActionType.SET_ATTEMPTS, t.array(AddressAttempt));

export const RecordAddressAttempt = AbstractSyncedAction(ActionType.RECORD_ADDRESS_ATTEMPT, AddressAttempt);


// ============================================================================
// User Preference Actions
// ============================================================================
export const SetPref = AbstractSyncedAction(ActionType.SET_PREF, t.partial(UserPrefs.props));



// ============================================================================
// Resource Groups
// ============================================================================
// export const RootActions = t.union([
//     ResetStore,
// ]);
export const RootActions = ResetStore;
export const UIActions = t.union([
    SetLayout,
    SetNavDrawerOpen,
    SetIsOnline,
    UpdateNavBar,
    SetTerritoryGroupFilter,
]);
export const GeoActions = SetGeoLocation;
export const PermissionActions = t.union([
    SetReduxEventMeta,
    SetCurrentUser,
    SetServiceGroups,
    SetActiveGroup,
    SetUserRoles,
]);
export const TagActions = t.union([
    SetAddressTags,
    SetResultTags,
]);
export const TerritoryActions = t.union([
    SetTerritories,
    UpdateTerritory,
    DeleteTerritory,
    CheckoutTerritory,
    AssignTerritory,
]);
export const TerritoryGroupActions = t.union([
    SetTerritoryGroups,
    UpdateTerritoryGroup,
    DeleteTerritoryGroup,
]);
export const PublisherActions = t.union([
    SetPublishers,
    UpdatePublisher,
    SetPublisherPassword,
    SetPublisherRoles,
    DeletePublisher,
    ReactivatePublisher,
]);
export const AddressActions = t.union([
    ClearAddresses,
    AppendAddresses,
    SetAddresses,
    AutoAssignAddresses,
    UpdateAddress,
    UpdateAddressNotes,
    GeocodeAddress,
    DeleteAddress,
]);
export const AssignmentActions = t.union([
    SetAssignments,
    UpdateAssignment,
    DeleteAssignment,
]);
export const AddressAttemptActions = t.union([
    ClearAttempts,
    AppendAttempts,
    SetAttempts,
    RecordAddressAttempt,
]);
export const PrefActions = SetPref;



// ============================================================================
// Action Groups
// ============================================================================
export const UnsyncedAction = t.union([
    ResetStore,
    SetLayout,
    SetNavDrawerOpen,
    SetIsOnline,
    UpdateNavBar,
    SetTerritoryGroupFilter,
    SetGeoLocation,
]);
export const SyncedAction = t.union([
    NullAction,
    SetReduxEventMeta,
    SetCurrentUser,
    SetServiceGroups,
    SetActiveGroup,
    SetUserRoles,
    SetAddressTags,
    SetResultTags,
    SetTerritories,
    UpdateTerritory,
    DeleteTerritory,
    CheckoutTerritory,
    AssignTerritory,
    SetTerritoryGroups,
    UpdateTerritoryGroup,
    DeleteTerritoryGroup,
    SetPublishers,
    UpdatePublisher,
    SetPublisherPassword,
    SetPublisherRoles,
    DeletePublisher,
    ReactivatePublisher,
    ClearAddresses,
    AppendAddresses,
    SetAddresses,
    AutoAssignAddresses,
    UpdateAddress,
    UpdateAddressNotes,
    GeocodeAddress,
    DeleteAddress,
    SetAssignments,
    UpdateAssignment,
    DeleteAssignment,
    ClearAttempts,
    AppendAttempts,
    SetAttempts,
    RecordAddressAttempt,
    SetPref,
]);
export const BaruchAction = t.union([
    UnsyncedAction,
    SyncedAction,
]);
export const UnsyncedActions = t.array(UnsyncedAction);
export const SyncedActions = t.array(SyncedAction);
export const BaruchActions = t.array(BaruchAction);



// ============================================================================
// Action Payloads from Server
// ============================================================================
export const BaruchServerAction = t.intersection([
    SyncedAction,
    t.partial({
        author: nullable(t.number),
        created_datetime: nullable(JSONDate),
    }),
]);
export const BaruchServerActions = t.array(BaruchServerAction);


interface ServerStatus2xxBrand {
    readonly ServerStatus2xx: unique symbol;
}
interface ServerStatus3xxBrand {
    readonly ServerStatus3xx: unique symbol;
}
interface ServerStatus4xxBrand {
    readonly ServerStatus4xx: unique symbol;
}

const ServerStatus2xx = t.brand(
    t.number,
    (status): status is t.Branded<number, ServerStatus2xxBrand> => (status >= 200 && status < 300),
    'ServerStatus2xx');
const ServerStatus3xx = t.brand(
    t.number,
    (status): status is t.Branded<number, ServerStatus3xxBrand> => (status >= 300 && status < 400),
    'ServerStatus3xx');
const ServerStatus4xx = t.brand(
    t.number,
    (status): status is t.Branded<number, ServerStatus4xxBrand> => (status >= 400 && status < 500),
    'ServerStatus4xx');

export const RXServerEvent2xx = t.interface({
    status: ServerStatus2xx,
    data: BaruchServerAction,
});
export const RXServerEvent3xx = t.interface({
    status: ServerStatus3xx,
    data: BaruchServerAction,
});
export const RXServerEvent4xx = t.interface({
    status: ServerStatus4xx,
    data: t.unknown,
});

export const RXServerEvent = t.union([
    RXServerEvent2xx,
    RXServerEvent3xx,
    RXServerEvent4xx,
]);



// ============================================================================
// Type Guards
// ============================================================================
export const isUnsyncedAction = (action: unknown): action is t.TypeOf<typeof UnsyncedAction> => {
    return isRight(UnsyncedAction.decode(action));
};

export const isSyncedAction = (action: unknown): action is t.TypeOf<typeof SyncedAction> => {
    return isRight(SyncedAction.decode(action));
};

export const isBaruchAction = (action: unknown): action is t.TypeOf<typeof BaruchAction> => {
    return isRight(BaruchAction.decode(action));
};

export const isBaruchServerAction = (action: unknown): action is t.TypeOf<typeof BaruchServerAction> => {
    return isRight(BaruchServerAction.decode(action));
};

export const isServerStatus2xx = (event: t.TypeOf<typeof RXServerEvent>): event is t.TypeOf<typeof RXServerEvent2xx> => {
    return event.status >= 200 && event.status < 300;
};

export const isServerStatus3xx = (event: t.TypeOf<typeof RXServerEvent>): event is t.TypeOf<typeof RXServerEvent3xx> => {
    return event.status >= 300 && event.status < 400;
};

export const isServerStatus4xx = (event: t.TypeOf<typeof RXServerEvent>): event is t.TypeOf<typeof RXServerEvent4xx> => {
    return event.status >= 400 && event.status < 500;
};
