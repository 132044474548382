import * as t from 'io-ts';


export const DRFSelectOption = t.interface({
    value: t.string,
    display_name: t.string,
});

const AbstractBaseField = <T extends t.Mixed, E extends t.Mixed>(type: T, extra: E) => {
    return t.intersection([
        t.interface({
            type: type,
            required: t.boolean,
            read_only: t.boolean,
            label: t.string,
        }),
        t.partial({
            help_text: t.string,
        }),
        extra,
    ]);
};

export const UnknownField = AbstractBaseField(
    t.literal('field'),
    t.interface({}));

export const IntegerField = AbstractBaseField(
    t.literal('integer'),
    t.interface({}));

export const BooleanField = AbstractBaseField(
    t.literal('boolean'),
    t.interface({}));

export const DatetimeField = AbstractBaseField(
    t.literal('datetime'),
    t.interface({}));

export const StringField = AbstractBaseField(
    t.keyof({
        "string": null,
        "email": null,
    }),
    t.partial({
        max_length: t.number,
    }));

export const ChoiceField = AbstractBaseField(
    t.literal("choice"),
    t.interface({
        choices: t.array(DRFSelectOption),
    }));

export const NestedObjectField = AbstractBaseField(
    t.literal('nested object'),
    t.interface({}));

export const Field = t.union([
    UnknownField,
    IntegerField,
    BooleanField,
    DatetimeField,
    StringField,
    ChoiceField,
    NestedObjectField,
]);

export const Method = t.keyof({
    'GET': null,
    'HEAD': null,
    'OPTIONS': null,
    'POST': null,
    'PUT': null,
    'PATCH': null,
    'DELETE': null,
});


export const DRFOptionsResponse = t.interface({
    name: t.string,
    description: t.string,
    renders: t.array(t.string),
    parses: t.array(t.string),
    actions: t.record(
        Method,
        t.record(
            t.string,
            Field
        )
    ),
});
